representative-example {
    display: block;
    background: $blue1;
    text-align: center;
    padding: $gap2;

    main > & {
        h5 {
            color: $white;
        }
    }

    h5 {
        font-weight: 700;
        margin-bottom: 1em;
    }

    p {
        color: $white;
        margin-bottom: 1em;

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
