#we-buy-your-car{
    main{
        margin-top: 36px;
        h1, h2, p{
            text-align: center;
        }
        h1{
            text-transform: uppercase;
            color: $blue2;
        }
        h2{
            font-size: 20px;
            margin-bottom: 32px;
        }
        content{
            margin-top: 40px;
        }
    }

}
