// Style up all sorts of text input fields

@mixin input-fields($types : color date datetime datetime-local email month number password search tel text time url week textarea) {

  $new-list:();

  @each $type in $types {
    @if $type == textarea or $type == select {
      $new-list: append($new-list, $type, comma);
    } @else {
      $new-list: append($new-list, #{'input[type=' + $type + ']'}, comma);
    }
  }
  #{$new-list} {
    @content;
  }
}
