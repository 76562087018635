/// Transition mixin handles animation for any number of transitions in any format/order
///
/// @param {arglist} $args - Pass in the following transtion settings, in any order.
///   [properties] - width, margin, rotate, skew, color, etc...
///   [duration]   - duration times are always calculated in seconds (not miliseconds). You do not have to define the 's'
///   [delay]      - delay times are always calculated in seconds (not miliseconds). You do not have to define the 's'
///   [ease-type]  - ease-in, ease-in-out etc... You can also refer to the eases variables. $easeInSine, $easeInOutBack
///   [important]  - If true, this will add the important selector

@mixin transition($args...) {

	$defaults : (
		duration : 0.2s,
		delay    : 0s,
		ease     : ease-in-out
	);

	$values : ();
	$important : null;

	@if length($args) == 0 {
		$args : $defaults;
	}

	@each $settings in $args {

		$properties : ();
		$duration : null;
		$delay    : null;
		$ease     : null;

		@each $setting in $settings {

			// Check for important ---------------------------------------------------

			@if is-important($setting) {
				$important : !important;
			}

			// Manage Strings --------------------------------------------------------

			@elseif is-string($setting) {

				@if contains(str-slice($setting, 0, 4), ease line step cubi init) {
					$ease : $setting;
				} @elseif contains($setting, x y transform matrix translate translateX translateY scale scaleX scaleY rotate skew skewX skewY matrix3d translate3d translateZ scale3d scaleZ rotate3d rotateX rotateY rotateZ perspective) {
					$properties : append($properties, transform);
				} @else {
					$properties : append($properties, $setting);
				}

			}

			// Manage Numbers --------------------------------------------------------

			@elseif is-number($setting) {

				@if is-null($duration) {
					$duration : add-unit($setting, $default-timing-unit);
				} @elseif is-null($delay) {
					$delay : add-unit($setting, $default-timing-unit);
				}

			}

		}

		@if length($properties) == 0 {
			$properties : all;
		}

		// Resolve defaults --------------------------------------------------------

		$duration : if(is-null($duration), map-get($defaults, duration), $duration);
		$delay : if(is-null($delay), map-get($defaults, delay), $delay);
		$ease : if(is-null($ease), map-get($defaults, ease), $ease);

		// Store values ------------------------------------------------------------

		@each $property in $properties {
			$values : append($values, #{$property $duration $delay $ease}, comma);
		}

	}

	transition : $values $important;

}
