#home,
.landing-pages,
.locations {

    // Custom positioning for altered logo
    navigation-bar {
        top: 54px;
    }

    main {
        #content {
            position: relative;
            width: 100%;
            min-height: 500px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            background-color: $grey;
            padding: 32px 0 0;

            h2.strapline{
                color: $green;
            }

            .promo {
                width: 100%;
                padding: 0 24px 0;

                h1 {
                    text-transform: capitalize;
                    font-size: 28px;
                    font-weight: 100;
                    margin-top: 0;
                }

                .trustpilot-logo-img {
                    position: relative;
                    top: unset;
                    left: unset;
                    transform: none;
                    margin: 16px auto 24px;
                    max-width: 250px;
                }

                trustpilot-badge {
                    flex-direction: row;
                    justify-content: space-around;
                    align-items: center;
                    margin: 16px auto;

                    div {
                        max-width: 120px;

                        &.stars {
                            margin-left: 0;
                        }
                    }

                    svg {
                        path.logo__star {
                            display: none;
                        }
                    }
                }
            }

            >svg {
                z-index: 2;

                &:first-of-type {
                    display: none;
                    width: 700px;
                }

                &:last-of-type {
                    display: block;
                    width: 300px;
                }
            }

            input[type="submit"],
            button[type="submit"] {
                font-size: 24px;
            }

            .badges {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                width: 100%;

                >* {
                    margin-top: $gap1;
                    max-width: 100%;
                }
            }

            h3 {
                text-align: center;

                a {
                    color: $green;

                    &:hover {
                        color: $blue2;
                    }
                }
            }

            form {
                margin: 0 auto;
                border-bottom: 32px solid white;
                padding: 0 24px 24px;
                background-color: $grey;
                text-align: center;

                h2 {
                    text-align: center;
                    font-weight: 600;
                    font-size: 14px;
                    margin: 0px auto 0;
                    text-transform: capitalize;
                    line-height: 24px;
                }

                application-form {
                    display: block;
                    padding: 16px 0 0 0;

                    .form-notice {
                        text-transform: uppercase;
                        letter-spacing: 3px;
                    }
                }

                input-fields {
                    width: 100%;

                    input {
                        &:not(.reg-input) {
                            background-color: $blue1;
                            color: white;

                            &::placeholder {
                                color: white;
                            }
                        }
                    }
                }

                .checkboxes {
                    display: block;
                    width: 100%;
                    .inputs{
                        flex-basis: fit-content;
                        margin: 16px 0;
                    }
                }

                @include break($medium, '>') {
                    padding: 10px 100px 50px;

                    application-form,
                    .checkboxes {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: center;
                        .inputs{
                            margin: 0;
                        }
                    }
                }
            }
        }

        contact-details {
            padding: 0;
            margin-bottom: $gap3;
        }

        representative-example {
            margin-bottom: 64px;
        }
    }

    @include break(320px, '>') {
        main #content {

            .promo h1 {
                font-size: 34px;
            }

            form h2 {
                font-size: 16px;
                padding: 0 24px;
            }
        }
    }

    // TODO: refactor this - we ALWAYS do MOBILE FIRST
    @include break($small-medium, '>') {
        main {
            #content {
                .promo#cash-in-your-bank h1 {
                    font-size: 30px;
                }
            }
        }
    }

    // End refactor
    @include break($medium, '>') {

        main {
            padding-top: 4px;

            #content {
                padding: 32px 0;
                margin-bottom: 32px;

                .promo#cash-in-your-bank h1 {
                    font-size: 50px;
                    line-height: 41px;
                    margin-bottom: 20px;
                }

                .promo {
                    .trustpilot-logo-img {
                        margin: 4px auto 20px;
                    }

                    trustpilot-badge {
                        max-width: 400px;

                        div {
                            max-width: 240px;
                        }
                    }
                }

                form {
                    h2 {
                        font-size: 22px;
                    }

                    padding:0px 10px;
                    margin: 0px auto 0;
                    border-bottom: none;

                    application-form {
                        padding: 32px 0 0 0;

                        .inputs {
                            padding: 10px 0;
                            max-width: 48%;
                            margin-right: 1%;
                            text-align: right;

                            &:last-of-type {
                                text-align: left;
                                margin-right: auto;
                                margin-left: 1%;
                            }
                        }

                        .checkboxes {
                            .inputs {
                                max-width: 100%;
                                margin: 0 0 0 1%;
                                text-align: left;

                                &:first-of-type{
                                    margin: 0 1.3%;
                                }
                            }
                        }
                    }
                }

                .badges {
                    flex-direction: row;

                    >* {
                        margin-top: $gap2;
                    }
                }

                >svg {
                    &:first-of-type {
                        display: block;
                    }

                    &:last-of-type {
                        display: none;
                    }
                }

                .loyc-logo {
                    width: 700px;
                }
            }
        }
    }

    @include break(930px, '>') {
        main {
            #content {
                .badges {
                    >* {
                        &+* {
                            margin-left: $gap2;
                        }
                    }
                }
            }
        }
    }

    @include break($large, '>') {
        main {
            #content {
                .promo h1 {
                    font-size: 50px;
                }
                .badges {
                    >* {
                        margin-top: $gap3;

                        &+* {
                            margin-left: $gap4;
                        }
                    }
                }
            }
        }
    }
}
