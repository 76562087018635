////////////////////////////////////////////////////////////////////////////////
// Settings                                                            #settings
////////////////////////////////////////////////////////////////////////////////

@mixin settings() {

	// =============================================================================
	// Options                                                              #options
	// =============================================================================

	// Show warnings
	$warnings : true !default !global;

	// Include a variety of custom-element and markup styling
	$global-styling : true !default !global;

	// Default border radius
	$border-radius : 10px !default !global;

	// Default units
	$default-unit : px !default !global;

	// Default spacer units
	$default-space-unit : vh !default !global;

	// Default units for fonts and line-heights
	$default-font-unit : px !default !global;

	// Default units for timings
	$default-timing-unit : s !default !global;

	$default-custom-property : primary !default !global;

	// This only works when css variable colours are defined as an RGB list, NOT Hex colours.
	$support-css-variable-alpha-colours : false !default !global;
	$support-css-variable-alpha-colors : $support-css-variable-alpha-colours !default !global;

	// =============================================================================
	// Normalisers                                                        #normalise
	// =============================================================================

	$normalise                   : true  !default !global;
	$normalise-section-elements  : true  !default !global;
	$normalise-header-elements   : true  !default !global;
	$normalise-grouping-elements : true  !default !global;
	$normalise-table-elements    : true  !default !global;
	$normalise-form-elements     : true  !default !global;
	$normalise-embedded-elements : true  !default !global;
	$normalise-text-elements     : true  !default !global;
	$normalise-emailers          : false !default !global;

	// =============================================================================
	// Aspect Ratios                                                  #aspect-ratios
	// =============================================================================

	$aspect-ratios : (
	  4x3   : 75%,
	  16x9  : 56.25%,
	  16x10 : 62.5%,
	) !default !global;

	// =============================================================================
	// Directories                                                      #directories
	// =============================================================================

	$images  : "/assets/images/"         !default !global;
	$sprites : "/assets/images/sprites/" !default !global;
	$fonts   : "/assets/fonts/"          !default !global;

	// =============================================================================
	// Gaps                                                                    #gaps
	// =============================================================================

	$gaps : 16     !default !global;
	$gap  : add-unit($gaps,   $default-unit) !default !global;
	$gap0 : add-unit($gaps/2, $default-unit) !default !global;
	$gap1 : $gap !default !global;
	$gap2 : add-unit($gaps*2, $default-unit) !default !global;
	$gap3 : add-unit($gaps*3, $default-unit) !default !global;
	$gap4 : add-unit($gaps*4, $default-unit) !default !global;
	$gap5 : add-unit($gaps*5, $default-unit) !default !global;
	$gap6 : add-unit($gaps*6, $default-unit) !default !global;
	$gap7 : add-unit($gaps*7, $default-unit) !default !global;
	$gap8 : add-unit($gaps*8, $default-unit) !default !global;
	$gap9 : add-unit($gaps*9, $default-unit) !default !global;

	// =============================================================================
	// Gutters                                                              #gutters
	// =============================================================================

	$gutter-left     : $gap2 !default !global;
	$gutter-right    : $gap2 !default !global;
	$gutters         : add-unit($gutter-left + $gutter-right) !default !global;

	// =============================================================================
	// Spacers                                                              #spacers
	// =============================================================================

	$spacing:            5 !default !global;
	$vertical-spacing:   $spacing !default !global;
	$horizontal-spacing: $spacing !default !global;

	// Horizontal Spacers
	$hspace:  space(1, vw, $horizontal-spacing) !default !global;
	$hspace1: $hspace !default !global;
	$hspace0: space(0, vw, $horizontal-spacing) !default !global;
	$hspace2: space(2, vw, $horizontal-spacing) !default !global;
	$hspace3: space(3, vw, $horizontal-spacing) !default !global;
	$hspace4: space(4, vw, $horizontal-spacing) !default !global;
	$hspace5: space(5, vw, $horizontal-spacing) !default !global;

	// Vertical Spacers
	$vspace:  space(1, vh, $vertical-spacing) !default !global;
	$vspace1: $vspace !default !global;
	$vspace0: space(0, vh, $vertical-spacing) !default !global;
	$vspace2: space(2, vh, $vertical-spacing) !default !global;
	$vspace3: space(3, vh, $vertical-spacing) !default !global;
	$vspace4: space(4, vh, $vertical-spacing) !default !global;
	$vspace5: space(5, vh, $vertical-spacing) !default !global;

	// As this is likely to be used more often, these are aliased to the vspace variables
	$space:  $vspace  !default !global;
	$space1: $vspace1 !default !global;
	$space0: $vspace0 !default !global;
	$space2: $vspace2 !default !global;
	$space3: $vspace3 !default !global;
	$space4: $vspace4 !default !global;
	$space5: $vspace5 !default !global;

	// =============================================================================
	// Miscellaneous
	// =============================================================================

	// Shorthand for !important (for the lazy)
	$imp : !important !default !global;

	// =============================================================================
	// Media Quries & Breakpoints                                     #media-queries
	// =============================================================================

	$mobile-first:  false !default !global;

	$max    : 1440 !default !global;
	$min    : 320  !default !global;
	$small  : 480  !default !global;
	$large  : 1200 !default !global;
	$medium : (($large - $small) / 2) + $small !default !global; // 840px
	$small-medium: (($medium - $small) / 2) + $small !default !global; // 630px

	$max    : add-unit($max) !global;
	$min    : add-unit($min) !global;
	$small  : add-unit($small) !global;
	$large  : add-unit($large) !global;
	$medium : add-unit($medium) !global;
	$small-medium : add-unit($small-medium) !global;

	$show-breakpoints : false !default !global;

	// =============================================================================
	// Notches                                                              #notches
	// =============================================================================

	$support-notches : false !default !global;

	$notches : (
	  iphoneX : 30pt 209pt,
	) !default !global;

	// =============================================================================
	// Text
	// =============================================================================

	@if $default-font-unit == 'rem' {
	  $font-size: add-unit(1.6, $default-font-unit) !default !global;
	  $h1: add-unit(remove-unit($font-size)*2.5,                                     $default-font-unit) !default !global; // 4.0em
	  $h2: add-unit((($h1 - remove-unit($font-size)) / 2) + remove-unit($font-size), $default-font-unit) !default !global; // 2.8em
	  $h3: add-unit((($h2 - remove-unit($font-size)) / 2) + remove-unit($font-size), $default-font-unit) !default !global; // 2.2em
	  $h4: add-unit((($h3 - remove-unit($font-size)) / 2) + remove-unit($font-size), $default-font-unit) !default !global; // 1.9em
	  $h5: add-unit((($h4 - remove-unit($font-size)) / 2) + remove-unit($font-size), $default-font-unit) !default !global; // 1.75em
	  $h6: add-unit((($h5 - remove-unit($font-size)) / 2) + remove-unit($font-size), $default-font-unit) !default !global; // 1.675em
	} @else {
	  $font-size: add-unit(16, $default-font-unit) !default !global;
	  $h1: add-unit(ceil((remove-unit($font-size)*2.5)),                                   $default-font-unit) !default !global; // 40px
	  $h2: add-unit(ceil((($h1 - remove-unit($font-size)) / 2) + remove-unit($font-size)), $default-font-unit) !default !global; // 28px
	  $h3: add-unit(ceil((($h2 - remove-unit($font-size)) / 2) + remove-unit($font-size)), $default-font-unit) !default !global; // 22px
	  $h4: add-unit(ceil((($h3 - remove-unit($font-size)) / 2) + remove-unit($font-size)), $default-font-unit) !default !global; // 19px
	  $h5: add-unit(ceil((($h4 - remove-unit($font-size)) / 2) + remove-unit($font-size)), $default-font-unit) !default !global; // 17px
	  $h6: add-unit(ceil((($h5 - remove-unit($font-size)) / 2) + remove-unit($font-size)), $default-font-unit) !default !global; // 16px
	}

	// ===========================================================================
	// Colours
	// ===========================================================================

	// Commmon
	$red      : #97080E           !default !global;
	$red1     : $red              !default !global;
	$red2     : darken($red, 10)  !default !global;
	$red3     : lighten($red, 10) !default !global;

	$orange   : #DA4B0F              !default !global;
	$orange1  : $orange              !default !global;
	$orange2  : darken($orange, 10)  !default !global;
	$orange3  : lighten($orange, 10) !default !global;

	$yellow   : #E9B104              !default !global;
	$yellow1  : $yellow              !default !global;
	$yellow2  : darken($yellow, 10)  !default !global;
	$yellow3  : lighten($yellow, 10) !default !global;

	$green    : #488C13             !default !global;
	$green1   : $green              !default !global;
	$green2   : darken($green, 10)  !default !global;
	$green3   : lighten($green, 10) !default !global;

	$blue     : #1B55C0            !default !global;
	$blue1    : $blue              !default !global;
	$blue2    : darken($blue, 10)  !default !global;
	$blue3    : lighten($blue, 10) !default !global;

	$pink     : #E05D6B            !default !global;
	$pink1    : $pink              !default !global;
	$pink2    : darken($pink, 10)  !default !global;
	$pink3    : lighten($pink, 10) !default !global;

	$purple   : #613D5A              !default !global;
	$purple1  : $purple              !default !global;
	$purple2  : darken($purple, 10)  !default !global;
	$purple3  : lighten($purple, 10) !default !global;

	$grey     : #787878            !default !global;
	$grey1    : $grey              !default !global;
	$grey2    : darken($grey, 10)  !default !global;
	$grey3    : lighten($grey, 10) !default !global;

	$brown    : #774F38             !default !global;
	$brown1   : $brown              !default !global;
	$brown2   : darken($brown, 10)  !default !global;
	$brown3   : lighten($brown, 10) !default !global;

	$white    : #FFFFFF             !default !global;
	$white1   : $white              !default !global;
	$white2   : darken($white, 10)  !default !global;

	$black    : #000000 !default !global;
	$black1   : $black !default !global;
	$black2   : lighten($black, 10) !default !global;


	// Social Media
	$facebook:    #3A5897 !default !global;
	$twitter:     #00aced !default !global;
	$google:      #df5146 !default !global;
	$youtube:     #CD1F1F !default !global;
	$linkedin:    #1D77B5 !default !global;
	$instagram:   #E33F5E !default !global;
	$pinterest:   #BC071D !default !global;
	$dailymotion: #0066DC !default !global;
	$vine:        #14B487 !default !global;
	$snapchat:    #FCFB00 !default !global;
	$flickr:      #EB1D82 !default !global;
	$vimeo:       #aad450 !default !global;
	$wordpress:   #20759A !default !global;
	$blogger:     #F4811E !default !global;
	$thumbler:    #36465C !default !global;
	$reddit :     #FC4501 !default !global;
	$ello :       #221F1F !default !global;

	$social-media-colours : (
	  facebook:   $facebook,
	  twitter:    $twitter,
	  google:     $google,
	  googleplus: $google,
	  youtube:    $youtube,
	  linkedin:   $linkedin,
	  instagram:  $instagram,
	  pinterest:  $pinterest,
	  dailymotion:$dailymotion,
	  vine:       $vine,
	  snapchat:   $snapchat,
	  flickr:     $flickr,
	  vimeo:      $vimeo,
	  wordpress:  $wordpress,
	  blogger:    $blogger,
	  thumbler:   $thumbler,
	  reddit :    $reddit ,
	  ello :      $ello
	) !default !global;

	// Special Colours
	$yello:       #FFCB08      !default !global;
	$selection:   #B2D3FA      !default !global;
	$text-colour: $black       !default !global;
	$text:        $text-colour !default !global;

	// ===========================================================================
	// Breakpoints
	// ===========================================================================

	$_breakpoints:(
		min          : add-unit($min, px, true),
		small        : add-unit($small, px, true),
		small-medium : add-unit($small-medium, px, true),
		medium       : add-unit($medium, px, true),
		large        : add-unit($large, px, true),
		max          : add-unit($max, px, true)
	);

	@if variable-exists(breakpoints) {
		$breakpoints: map-merge($breakpoints, $_breakpoints) !global;
	} @else {
		$breakpoints : $_breakpoints !global;
	}

	// ===========================================================================
	// Custom Properties
	// ===========================================================================

	$set-custom-properties : true !default !global;

	$_custom-properties : (
	  gaps               : add-unit($gaps),
	  vertical-spacing   : add-unit($vertical-spacing, vh),
	  horizontal-spacing : add-unit($horizontal-spacing, vw),
	  gutters            : add-unit($gutters),
	  border-radius      : add-unit($border-radius),
	  font-size          : add-unit($font-size, $default-font-unit)
	);

	@if variable-exists(custom-properties) {
	  $custom-properties: map-merge($custom-properties, $_custom-properties) !global;
	} @else {
	  $custom-properties : $_custom-properties !global;
	}

	$custom-properties: map-merge($custom-properties, $breakpoints) !global;

}
