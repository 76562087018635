[data-browser="ie 11"]{
  .car-brands .scroll-bar nav ul:nth-of-type(2){
    width: 100%;
  }
}
.car-brands{
    overflow: hidden;

    h2 {
        font-size: 34px;
        color: #3f3f3f !important;
        text-align: center;
        font-weight: lighter;
        letter-spacing: 3px;
        margin-bottom: 32px;
    }
    .strapline{
        width: auto;
        font-weight: 700;
        font-size: 19px;
        color: #28b2ec;
        letter-spacing: 2px;
        text-align: center;
    }
}

.car-brands-info{
    display: block;
    margin: 0 auto;
    ul{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        max-width: 100%;
        justify-content: space-around;
        li{
            width: 48%;
            margin: 8px 0 8px 8px;
            display: flex;
            a{
                display: block;
                width: 100%;
                padding: 16px;
                background-color: white;
                color: #3ab4f0;
                transition: all .2s ease-in-out;
                text-align: left;
                font-weight: bold;
                position: relative;
                svg{
                    height: 16px;
                    width: 16px;
                    position: absolute;
                    right: 16px;
                    top: 16px;

                }
                &:hover{
                    background-color: #3ab4f0;
                    color: white;
                }

            }
            @include break($small-medium, '<') {
                width: 100%;
                margin: 8px 0;
            }
        }
    }

    .details-modal{
        position: fixed;
        background-color: white;
        z-index: 9;
        padding: 64px;
        width: 80vw;
        max-width: 1200px;
        max-height: 80vh;
        margin: 0 auto;
        opacity: 0;
        transition: all .2s ease-in-out;
        z-index: 9;
        left: 50%;
        top: 50% !important;
        transform: translate(-50%, -50%);
        &.active{
            transition-delay: .2s;
            opacity: 1;
            transition: all .2s ease-in-out;
        }
        img{
            display: block;
            margin: 0 auto;
            max-width: 250px;
            margin-bottom: 32px;
        }
        .brand-content{
            overflow-y: scroll;
            max-height: 40vh;
            text-align: left;
            p{
                margin-bottom: 8px;
            }
        }
        .x-btn{
            position: absolute;
            top: 32px;
            right: 32px;
            width: 32px;
            height: 32px;
            padding: 8px;
            background-color: #f7f7f7;
            border-radius: 50%;
            &:after{
                content: 'X';
            }
            &:hover{
                cursor: pointer;
            }
        }
        .close-btn{
            color: white;
            background-color: black;
            padding: 16px;
            max-width: 100px;
            margin: 32px auto 0;
            &:hover{
                cursor: pointer;
            }
            @include break($medium, '<') {
                margin: 16px auto 0;

            }
        }
        @include break($medium, '<') {
            width: 100vw;
            padding: 48px;
            max-height: 90vh;
        }
    }

}
.overlay{
    z-index: 8;
    height: 100%;
    width: 150vw;
    background-color: black;
    opacity: 0.5;
    display: none;
    position: absolute;
    top: 0;
    left: -50%;
    &.active{
        display: block;
    }
}
car-brands {
    margin: $gap2 0;
    height: 100%;
    display: flex;
    position: relative;
    padding: 16px 0;
    width: 100%;
    overflow: hidden;
    background-color: white;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: linear-gradient(to right, white 2%, rgba(255, 255, 255, 0) 15%, rgba(255, 255, 255, 0) 85%, white 98%);
    }

    ul {
        position: relative;
        animation: slider 60s linear 0s infinite normal forwards;
        display: inline-flex;
        width: auto;
        transform: translate3d(0, 0, 0);

        li {
            display: block;
            padding: 0 $gap2;
            opacity: 1;
            margin: 0 $gap2;
            line-height: 100px;
            height: 100px;

            img {
                margin: auto 0;
                max-width: 150px;
                vertical-align: middle;
            }
        }
    }
}

@mixin animation($animation...) {
	-o-animation: $animation;
	-moz-animation: $animation;
	-webkit-animation: $animation;
	animation: $animation;
}
@mixin translateX ($x) {
	@include transform(translateX($x));
}
@-webkit-keyframes slider {
	0% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}

	100% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}
@keyframes slider {
	0% {
		-webkit-transform: translateX(0px);
		transform: translateX(0px);
	}

	100% {
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
	}
}



@mixin special-width {
  width:calc(100vw + #{$gap2}); left:-$gap2;
  @media (min-width:$max - $gap*4) {
    width:100vw; transform:translateX(-50%); left:50%;
  }
}

@function spacer($half:false) {
  @if ($half == true) {
    @return calc(#{$gap} + 1.5vh);
  }
  @return calc(#{$gap2} + 3vh);
}
