//This will add a lineheight in percentage that's just a little bit greater than the font
@mixin font-size($args...) {

  $defaults : (
    font-size      : if(variable-exists(font-size),$font-size, 1.6),
    line-height    : null,
    letter-spacing : null,
  );

  $settings : get-settings($defaults, $args...);

  $font-size      : map-get($settings, font-size);
  $line-height    : map-get($settings, line-height);
  $letter-spacing : map-get($settings, letter-spacing);
  $important      : map-get($settings, important);

  font-size      : add-unit($font-size, $default-font-unit) $important;
  line-height    : add-unit($line-height, $default-font-unit) $important;
  letter-spacing : add-unit($letter-spacing, $default-font-unit) $important;

}
