#vehicle-equity-release-in-an-emergency {
    @extend .content-block;
    background-color: $grey;
    padding: $gap;

    header {
        svg {
            height: 100px;
            width: 100px;
            fill: $blue2;
            margin-bottom: 1em;
        }
        h2 {
            @extend h3;
        }
    }

    // Overrides
    .col-layout {
        > div {
            margin-top: $gap;

            &:first-of-type {
                margin-top: 0;
            }
        }
        h3 {
            color: $blue2;
            text-align: center;
        }
    }

    @media (min-width: $medium) {
        padding: $gap4;

        .col-layout {
            > div {
                margin-top: $gap2;
            }
            h3 {
                text-align: left;
            }
        }
    }
}
