@mixin selection($args...) {

  $defaults : (
    background : if(variable-exists(selection), $selection, #B2D3FB),
    colour     : if(variable-exists(selection), $selection, #B2D3FB),
    opacity    : 0.8,
  );

  $settings : get-settings($defaults, $args...);

  $background : map-get($settings, background);
  $colour     : map-get($settings, colour);
  $opacity    : map-get($settings, opacity);
  $important  : map-get($settings, important);

  @if ($colour == null or $colour == $background) {
    $fallback-value : get-variable-colour-value($background);
    $colour:colour-contrast($fallback-value);
  }

  ::-moz-selection {
    color: $colour $important;
    background-color : var($background);
    @content;
  }

  ::selection {
    color: $colour $important;
    background-color : var($background);
    @content;
  }
}
