// Pawn Your Car styles
body#pawn-your-car {

    // NEW STYLES
    site-wrapper {
        background-color: $thunder;
    }

    main p {
        line-height: 1.6;
        font-weight: 400;
        margin-bottom: revert;

        &:last-child {
            margin-bottom: 0;
        }
    }

    #content {
        >header {
            text-align: center;

            .brand-logo {
                display: block;
                width: 100%;
            }

            a {
                display: inline-block;
            }

            h1 {
                font-size: 26px;
                font-weight: 400;
                line-height: 1.2;
                color: $husk;
                margin-top: $gap2;
            }

            h2 {
                position: relative;
                color: $white;
                font-size: 16px;
                width: fit-content;
                margin: $gap2 auto 0;

                &:before,
                &:after {
                    content: '';
                    display: block;
                    height: 2px;
                    width: 0.5em;
                    background-color: $tulip-tree;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &:before {
                    left: -1.5em;
                }

                &:after {
                    right: -1.5em;
                }
            }

            img {
                width: 100%;
                max-width: 100%;
                // Offset form margins
                margin-bottom: -$gap2;
            }
        }

        @include break($medium, '>') {
            margin-top: $gap4;
            // NOTE: imgix compression adds colour banding to these images
            background-image: url('https://loyc.imgix.net/images/bg-masthead-porsche.png?q=100&auto=format');
            background-repeat: no-repeat;
            background-size: auto 90%;
            background-position: right center;

            >header {
                max-width: 65%;

                .brand-logo {
                    width: 600px;
                }

                h1 {
                    font-size: 38px;
                }

                h2 {
                    font-size: 18px;
                }

                img {
                    display: none;
                }
            }

            >form {
                max-width: 65%;
            }
        }
    }

    // Forms
    error-messages {
        background-color: $thunder;
    }

    input-fields {
        .radios {
            flex-basis: auto;
            max-width: 100%;
            width: 100%;
            color: $husk;

            .inputs {
                display: inline-flex;

                legend {
                    font-size: 1.2em;
                    font-weight: 400;
                }

                label {
                    color: $white;
                    margin-left: $gap2;
                    margin-top: $gap;
                }
            }

            .errors {
                text-align: center;
                margin-top: $gap0;
            }
        }

        @include break($medium, '>') {
            .radios {
                .inputs {
                    legend {
                        display: contents;
                    }

                    label {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    #lambo-bg {
        padding-bottom: 100px;
        // NOTE: imgix compression adds colour banding to these images
        background-image: url('https://loyc.imgix.net/images/bg-lamborghini.png?q=100&auto=format');
        background-repeat: no-repeat;
        background-size: 80% auto;
        background-position: center bottom;

        @include break($small, '>') {
            padding-bottom: 300px;
        }

        @include break($medium, '>') {
            padding-bottom: 400px;
        }

        @include break($large, '>') {
            padding-bottom: 520px;
        }
    }

    #withdraw-equity-prestige {
        @extend .content-block;
        color: $white;

        svg,
        img {
            max-height: 100px;
        }

        .wysiwyg-accordion {
            margin-top: 20px;

            > div > div {
                background-color: $mine-shaft;
                padding: $gap2 $gap2 $gap;

                & + div {
                    margin-top: $gap;
                }
            }

            h4 {
                position: relative;
                padding-right: 1em;
                margin-top: 0;

                &:after {
                    content: '';
                    transform: translateY(-70%) rotate(135deg);
                    height: 12px;
                    width: 12px;
                    border-right: 2px solid $white;
                    border-top: 2px solid $white;
                    transform-origin: center;
                    right: 0;
                    top: 50%;
                    position: absolute;
                    transition: transform 0.2s ease-in-out;
                }
                &:empty {
                    display: none;
                }

                &.open {
                    &:after {
                        transform: translateY(-30%) rotate(-45deg);
                    }
                }
            }

            p {
                max-height: 0;
                margin: 0;
                opacity: 0;
                transition: max-height 0.2s ease-in-out 0.2s,
                    margin 0.2s linear 0.2s,
                    opacity 0.2s ease-in-out;

                &.open {
                    overflow: hidden;
                    max-height: 1000em;
                    margin-bottom: revert;
                    opacity: 1;
                    transition: max-height 0.2s ease-in-out,
                        opacity 0.2s ease-in-out 0.2s;
                }
            }
        }

        // Overrides
        h3 {
            color: $husk;
            text-transform: initial;
            font-size: 36px;
            font-weight: 400;
            margin-top: 0.5em;
        }

        h4 {
            color: $tulip-tree;
            font-size: 18px;
            margin-top: 1em;
            line-height: 1.4;
        }

        .cta-container {
            margin-top: 1em;

            contact-details {
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0;
            }
        }

        .col-layout {
            >div {
                width: 100%;
                margin-bottom: 1em;
                text-align: center;

                &:nth-child(odd) {
                    p:first-of-type {
                        font-weight: 700;
                    }
                }
            }
        }

        @include break($small-medium, '>') {
            .col-layout {
                >div {
                    text-align: left;

                    h3 {
                        text-align: center;
                    }

                    &:nth-child(odd) {
                        text-align: center;
                    }
                }
            }

            .wysiwyg-accordion {
                > div > div {
                    position: relative;
                    padding: $gap2 $gap2 $gap $gap5;

                    &:before {
                        position: absolute;
                        left: $gap2;
                        content: '';
                        transform: rotate(45deg);
                        height: 1.5em;
                        width: 0.75em;
                        border-bottom: 6px solid $tobacco;
                        border-right: 6px solid $tobacco;
                    }
                }
                h4 {
                    padding-right: 0;
                    text-align: left;

                    &:after {
                        content: unset;
                    }
                }

                p {
                    max-height: unset;
                    opacity: 1;
                    margin-bottom: revert;
                }
            }
        }

        @include break($medium, '>') {
            .col-layout {
                >div {
                    &:nth-child(even) {
                        >div {
                            column-count: 2;

                            > div {
                                break-inside: avoid;
                            }
                        }
                    }
                }
            }
        }
    }

    #type-of-cars-pawned {
        @extend .content-block;
        color: $white;
        padding-top: 175px;
        text-align: center;
        // background-color: linear-gradient(0deg, $black 60%, $thunder 95%);
        // NOTE: imgix compression adds colour banding to these images
        background-image: url('https://loyc.imgix.net/images/bg-vintage-jaguar.png?q=100&auto=format');
        background-repeat: no-repeat;
        background-position: center 25px;
        background-size: 80% auto;

        header {
            padding: 0 $gap;

            .read-more-state {
                display: none;

                &:checked {
                    &~.read-more-trigger:before {
                        content: 'Show less';
                    }

                    &~.read-more-wrap {
                        max-height: none;

                        &:after {
                            content: unset;
                        }
                    }
                }
            }

            .read-more-wrap {
                position: relative;
                max-height: 160px;
                overflow: hidden;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(0deg, $thunder 0, rgba(255, 255, 255, 0) 35%);
                }
            }

            .read-more-trigger {
                color: $tulip-tree;
                margin-top: 1em;
                user-select: none;
                display: inline-block;

                &:before {
                    content: 'Read more...';
                }

                // Override some weird styles from _form.scss
                &:hover:before {
                    background: transparent;
                }
            }
        }

        h3 {
            color: $husk;
            text-transform: initial;
            font-size: 26px;
            font-weight: 400;
        }

        .car-card-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: $gap2;
            margin-top: $gap2;
        }

        .car-card {
            cursor: pointer;
            background-color: $mine-shaft;
            width: 100%;
            // NOTE: Do not stretch siblings to fill height
            align-self: flex-start;

            &.open {
                >div {
                    padding: $gap;
                    overflow: hidden;
                    max-height: 1000em;
                    opacity: 1;
                    transition: max-height 0.2s ease-in-out,
                        opacity 0.2s ease-in-out 0.2s;
                }
            }

            header {
                background-color: $grey-200;
                padding: $gap2;

                svg,
                img {
                    max-height: 75px;
                    margin-bottom: $gap;
                }

                h4 {
                    color: $husk;
                    text-transform: uppercase;
                    font-weight: 400;
                    font-size: 18px;
                    margin: 0;
                }
            }

            >div {
                max-height: 0;
                padding: 0;
                opacity: 0;
                transition: max-height 0.2s ease-in-out 0.2s,
                    padding 0.2s linear 0.2s,
                    opacity 0.2s ease-in-out;
            }
        }

        .button {
            margin-top: $gap2;
        }

        contact-details {
            padding-bottom: 0;
            margin-bottom: 0;
        }

        @include break($small, '>') {
            padding-top: 250px;

            header {
                .read-more-wrap {
                    max-height: 135px;
                }
            }
        }

        @include break($small-medium, '>') {
            header {
                padding: 0 $gap2;
            }

            .car-card {
                width: calc(50% - #{$gap});
            }
        }

        @include break($medium, '>') {
            padding-top: 350px;

            h3 {
                font-size: 36px;
            }
        }

        @include break($large, '>') {
            padding-top: 500px;

            .car-card {
                width: calc(33% - #{$gap2});
            }
        }
    }

    #cta-panes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $gap2;
        padding-top: 150px;
        // NOTE: imgix compression adds colour banding to these images
        background-image: url('https://loyc.imgix.net/images/bg-vintage-porsche.png?q=100&auto=format');
        background-repeat: no-repeat;
        background-size: 80% auto;
        background-position: center top;

        article {
            background-color: $mine-shaft;
            padding: $gap2;
            color: $white;
            width: 100%;

            >svg,
            >img {
                max-height: 100px;
                max-width: 100%;
            }

            h3 {
                color: $husk;
                font-weight: 400;
                font-size: 28px;
                text-transform: initial;
                margin-top: 1em;
            }
        }

        contact-details {
            padding-left: 0;
            padding-right: 0;
            text-align: left;

            svg {
                margin-left: 0;
            }
        }

        @include break($small, '>') {
            padding-top: 250px;
        }

        @include break($medium, '>') {
            padding-top: 400px;

            article {
                width: calc(50% - #{$gap});
            }
        }
    }

    #partner-referal-program-modal {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($mine-shaft, 0.75);
        opacity: 0;
        visibility: hidden;
        transition: opacity .4s ease-in-out;

        &.show {
            opacity: 1;
            visibility: visible;
        }

        form {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            max-width: 500px;
            width: 100%;
            background-color: $mine-shaft;
            border-radius: 4px;
            padding: $gap2;
            box-shadow: 0 0 10px rgba(black, 0.8);
            max-height: 85vh;
            overflow: scroll;

            h4 {
                text-align: center;
                color: $tulip-tree;
            }

            p {
                text-align: center;
                color: $white;
            }
        }

        .form-group {
            margin-top: $gap;

            label {
                color: $white;
            }

            input {
                margin-top: $gap;
                padding: 4px 6px
            }

            sup {
                color: $tulip-tree;
            }

            input[type="submit"],
            button[type="submit"] {
                background-color: $tulip-tree;
                color: $mine-shaft;
                text-transform: uppercase;
                padding: $gap;
                border-radius: 4px;
                font-weight: 700;
            }

            button {
                background-color: $dusty-gray;
                color: $mine-shaft;
                text-transform: uppercase;
                padding: $gap;
                border-radius: 4px;
                font-weight: 700;
                margin-left: $gap;
            }
        }

        .notices {
            margin-top: $gap;

            &:empty {
                display: none;
            }

            &.error {
                color: red;
            }

            &.success {
                color: $green;
            }

            p {
                color: inherit;
            }
        }
    }

    // OVERRIDES
    *::selection {
        background-color: $tulip-tree;
    }

    .social-icon {
        fill: $husk;
    }

    .content-block {
        @extend .content-block;

        p a:not(.button) {
            color: $white;
        }
    }

    // Application Form
    application-form {
        padding: $gap2 0;

        .submits {
            p {
                color: $white;
                // Apply again...
                line-height: 1;
                font-weight: 700;
                margin-bottom: 0;
            }

            // DEPRECATED 2023-07-07
            // .applyByPhone {
            //     color: $white;

            //     #callbackTime {
            //         border-color: $white;
            //         color: $tulip-tree;
            //     }
            // }
        }

        @include break($medium, '>') {
            .submits {
                width: 100%;
            }
        }

        @include break($large, '>') {
            .submits {
                width: inherit;
            }
        }
    }

    // Buttons
    .button {
        --button-theme:#{$tulip-tree};
        color: $thunder;

        &:hover,
        &:active,
        &:focus {
            background-color: darken($tulip-tree, 10%);
            color: $thunder;
        }
    }

    // Sticky Navigation
    .sticky-navigation {
        background-color: $mine-shaft;

        p {
            color: white;

            &:before {
                background-color: $tulip-tree;
            }
        }

        >ul li {
            border-right-color: $black;

            &:hover {
                background-color: $tulip-tree;
            }

            &:last-of-type {
                background-color: $tulip-tree;

                a {
                    color: $thunder;

                    &:before {
                        color: $white;
                    }

                    &:after {
                        background-color: $mine-shaft;
                    }
                }
            }
        }

        .menu-trigger {
            background-color: $black;

            svg {
                fill: $tulip-tree;
            }
        }
    }

    // Navigation Bar
    navigation-bar {
        background-color: $tulip-tree;

        &.open>a {
            background-color: $tulip-tree;
        }

        nav {
            background-color: inherit;
        }

        ul li a {
            color: $thunder;

            &:hover {
                background-color: darken($tulip-tree, 10%);
            }
        }
    }

    // Forms
    input-fields {
        .inputs a {
            color: $tulip-tree;
        }

        .checkboxes {
            color: $dusty-gray;

            label:before {
                background-color: $mine-shaft;
                border-width: 1px;
                border-color: $dusty-gray;
            }

            label:after {
                color: $white;
            }

            label:hover {
                color: lighten($dusty-gray, 10%);

                &:before {
                    border-color: $tulip-tree;
                }
            }
        }

        input {
            &.large {
                background-color: $mine-shaft;
                color: $white;
                border-color: transparent;

                &::-webkit-input-placeholder {
                    color: $white;
                }

                &::-moz-placeholder {
                    color: $white;
                }

                &:-ms-input-placeholder {
                    color: $white;
                }

                &::-ms-input-placeholder {
                    color: $white;
                }

                &:-moz-placeholder {
                    color: $white;
                }

                &.reg-input {
                    background-color: $husk;
                    color: $black;

                    &::-webkit-input-placeholder {
                        color: $black;
                        font-weight: 600;
                    }

                    &::-moz-placeholder {
                        color: $black;
                        font-weight: 600;
                    }

                    &:-ms-input-placeholder {
                        color: $black;
                        font-weight: 600;
                    }

                    &::-ms-input-placeholder {
                        color: $black;
                        font-weight: 600;
                    }

                    &:-moz-placeholder {
                        color: $black;
                        font-weight: 600;
                    }
                }
            }
        }

        @include break($medium, '>') {
            >div {
                max-width: calc(50% - #{$gap});
                flex-basis: 50%;
                margin-bottom: $gap;
            }

            .checkboxes {
                display: flex;
                justify-content: space-between;
                max-width: 100%;

                >div {
                    margin-left: 0;
                }

                .inputs {
                    &+.inputs {
                        margin-left: $gap;
                    }
                }
            }
        }
    }

    // Contact Details
    contact-details {
        color: $white;

        p strong {
            color: $tulip-tree;
        }

        p a {
            color: $white;
        }

        svg {
            fill: $husk;
        }
    }

    // Truspilot
    #trustpilot-carousel {
        color: $white;
        padding: 0;
        margin: 0;

        h3 {
            color: $husk;
            text-transform: initial;
            font-size: 26px;
            font-weight: 400;
        }

        .wrapper-right .review {
            .relative-date {
                color: $white;
            }

            .name {
                color: $white;
            }
        }

        .slick-arrow {
            background-color: black;
        }

        @include break($medium, '>') {
            h3 {
                font-size: 36px;
            }
        }
    }

    // Representative Example
    representative-example {
        background-color: $mine-shaft;

        h5 {
            color: $tulip-tree;
        }
    }

    // How it Works
    #how-it-works {
        background-color: transparent;
        padding-top: $gap2;
        padding-bottom: $gap2;
        margin-bottom: 0;

        h2 {
            text-transform: initial;
            color: $husk;
            font-weight: 400;
            font-size: 26px;
            margin-bottom: $gap0;
        }

        p {
            color: $white;
            text-align: center;
        }

        .col-layout {
            &:before {
                background-color: $mine-shaft;
                bottom: 10%;
            }

            &>div {
                &:before {
                    background-color: $tobacco;
                }

                &:nth-of-type(2n) {
                    &:before {
                        background-color: $tulip-tree;
                    }
                }

                &:nth-of-type(3n) {
                    &:before {
                        background-color: $husk;
                    }
                }
            }
        }

        @include break($medium, '>') {
            padding-top: $gap4;
            padding-bottom: $gap4;

            h2 {
                font-size: 36px;
            }
        }
    }

    // USPs
    unique-selling-points {
        padding: 0;

        usp-blocks {
            text-align: initial;
            gap: $gap2;

            div {
                background-color: $mine-shaft;
                color: $white;
                padding: $gap2;

                &.open {
                    h3:after {
                        transform: translateY(-30%) rotate(-45deg);
                    }

                    p {
                        overflow: hidden;
                        max-height: 1000em;
                        margin-top: 1em;
                        margin-bottom: revert;
                        opacity: 1;
                        transition: max-height 0.2s ease-in-out,
                            opacity 0.2s ease-in-out 0.2s;
                    }
                }

                svg {
                    display: none;
                }

                h3 {
                    position: relative;
                    color: $husk;
                    text-transform: initial;
                    padding-right: 1em;
                    margin: 0;

                    &:after {
                        content: '';
                        transform: translateY(-70%) rotate(135deg);
                        height: 12px;
                        width: 12px;
                        border-right: 2px solid $white;
                        border-top: 2px solid $white;
                        transform-origin: center;
                        right: 0;
                        top: 50%;
                        position: absolute;
                        transition: transform 0.2s ease-in-out;
                    }
                }

                p {
                    max-height: 0;
                    margin: 0;
                    opacity: 0;
                    transition: max-height 0.2s ease-in-out 0.2s,
                        margin 0.2s linear 0.2s,
                        opacity 0.2s ease-in-out;
                }
            }

            &:not(:empty)+.button {
                margin-top: $gap2;
            }
        }

        @include break($medium, '>') {
            usp-blocks {
                div {
                    padding: $gap2 $gap2 $gap2 $gap5;
                    width: calc(50% - #{$gap});

                    svg {
                        display: inline-block;
                        vertical-align: middle;
                        fill: $tobacco;
                        height: $gap2;
                        margin-left: -60px;
                    }

                    h3 {
                        display: inline-block;
                        padding-right: 0;

                        &:after {
                            content: unset;
                        }
                    }

                    p {
                        max-height: unset;
                        opacity: 1;
                        margin-top: revert;
                        margin-bottom: revert;
                    }
                }

            }
        }
    }

    #pawn-your-car-faqs {
        padding: $gap2 0;

        h3 {
            color: $husk;
            font-size: 26px;
            font-weight: 400;
            text-transform: initial;
            text-align: center;
        }

        article.accordion {
            background-color: $thunder;
            color: $white;

            &+.accordion {
                margin-top: $gap2;
            }

            .title {
                background-color: $mine-shaft;

                &:after {
                    border-right-color: $husk;
                    border-top-color: $husk;
                }
            }
        }

        @include break($medium, '>') {
            padding: $gap6 0;

            h3 {
                font-size: 36px;
            }

            .accordion-container {
                max-width: 85%;
                margin: 0 auto;
            }
        }

        @include break($large, '>') {
            .accordion-container {
                max-width: 65%;
                margin: 0 auto;
            }
        }
    }


    .footer-wrapping {
        fca-footer {
            >img {
                max-height: 70px;
            }

            p {
                color: $white;
            }
        }

        footer {
            background-color: $mine-shaft;

            .pyc-logo {
                width: 100%;
                max-width: 340px;
                margin-bottom: $gap;
            }

            .footer-wrap>div:last-of-type ul li:first-of-type {
                color: $tulip-tree;
            }

            .footer-wrap>div:first-of-type a {
                color: $tulip-tree;
            }
            .footer-wrap {
                > div {
                    a {
                        &:hover, &:active, &:focus {
                            color: $tulip-tree;
                        }
                    }
                }
            }
        }
    }
}
