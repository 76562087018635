/// Define a buttons default and hover styling. Includes a support for css variables and inverting
///
/// @author Mark Notton
///

///
/// @param {list | colour | css variable} $static-colours :
///         Pass in the 2 colours (without a comma). First colour is for text,
///         the second is for the background. You can define a css variable if
///         you have a $variables sass map defined. You may pass in one colour if
///         you later define this button to be inverted (as backgrounds aren't necessary)
/// @param {list | colour | css variable} $hover-colours : Same as the previous param.
///         However this param is optional. Ommiting this option will just reverse
///         the colours from the first param. Inverted options can support static
///         and background colours on hover.
/// @param {bool|string} $borderless - 'borderless' or 'false' will exclude any border colour
/// @param {string} $inverted - 'inverted' or 'true' will trigger an inverted styling.
///         This isn't a simple reverse of the first $static-colours and $hover-colours options.
///         This assumes you want the static state to have no background colour at all.
///
/// @example 1 scss - Usage
///
/// @include button(
/// 	$initial:(
/// 		'background' : red,
/// 		'colour'     : white,
/// 	),
/// 	$hover:(
/// 		'background' : red,
/// 		'colour'     : red,
/// 		'fill'       : purple,
/// 		'stroke'     : green,
/// 		'border'     : yellow
/// 	)
/// );
@mixin button($args...) {

	$transitions : ();
	$svg-transitions : ();

	// Manage settings -----------------------------------------------------------

	$defaults : (
	  'initial' : (
			'background' : white,
			'colour' : var(primary),
			'fill' : null,
			'stroke' : null,
			'border' : null,
			'important' : null,
		),
		'hover' : (
			'background' : var(secondary),
			'colour' : white,
			'fill' : null,
			'stroke' : null,
			'border' : null,
			'important' : null,
		),
		'transitions' : true
	);

	$settings : get-settings($defaults, $args...);

	$initial : map-get($settings, initial);
  $hover   : map-get($settings, hover);
  $add-transitions : map-get($settings, transitions);

	// Privately define all the default colours as provided ----------------------

	$_background : map-get($initial, background);
	$_colour     : map-get($initial, colour);
	$_fill       : map-get($initial, fill);
	$_stroke     : map-get($initial, stroke);
	$_border     : map-get($initial, border);
	$_important  : is-important(map-get($initial, important));

	$_hover-background : map-get($hover, background);
	$_hover-colour     : map-get($hover, colour);
	$_hover-fill       : map-get($hover, fill);
	$_hover-stroke     : map-get($hover, stroke);
	$_hover-border     : map-get($hover, border);
	$_hover-important  : is-important(map-get($hover, important));

	// Check if the colours are colours or custom properties. --------------------

	$background : if(is-colour($_background) or is-var($_background), $_background, null);
	$colour     : if(is-colour($_colour) or is-var($_colour), $_colour, null);
	$fill       : if(is-colour($_fill) or is-var($_fill), $_fill, null);
	$stroke     : if(is-colour($_stroke) or is-var($_stroke), $_stroke, null);
	$border     : if(is-colour($_border) or is-var($_border), $_border, null);

	$hover-background : if(is-colour($_hover-background) or is-var($_hover-background), $_hover-background, null);
	$hover-colour     : if(is-colour($_hover-colour) or is-var($_hover-colour), $_hover-colour, null);
	$hover-fill       : if(is-colour($_hover-fill) or is-var($_hover-fill), $_hover-fill, null);
	$hover-stroke     : if(is-colour($_hover-stroke) or is-var($_hover-stroke), $_hover-stroke, null);
	$hover-border     : if(is-colour($_hover-border) or is-var($_hover-border), $_hover-border, null);

	// Apply initial state properties --------------------------------------------

	@if $background {
		background-color:$background $_important;
		$transitions : add(background-color, $transitions);
	}
	@if $colour {
		color:$colour $_important;
		$transitions : add(color, $transitions);
	}
	@if $fill {
		svg { fill:$fill $_important; }
		$svg-transitions : add(fill, $svg-transitions);
	}
	@if $stroke {
		svg { stroke:$stroke $_important; }
		$svg-transitions : add(stroke, $svg-transitions);
	}
	@if $border {
		border-color:$border $_important;
		$transitions : add(border-color, $transitions);
	}

	// Apply hover state properties ----------------------------------------------

	@media ( hover : hover ) {
		&:hover {
			@if $hover-background {
				background-color:$hover-background $_hover-important;
				$transitions : add(background-color, $transitions);
			}
			@if $hover-colour {
				color:$hover-colour $_hover-important;
				$transitions : add(color, $transitions);
			}
			@if $hover-fill {
				svg { fill:$hover-fill $_hover-important; }
				$svg-transitions : add(fill, $svg-transitions);
			}
			@if $hover-stroke {
				svg { stroke:$hover-stroke $_hover-important; }
				$svg-transitions : add(stroke, $svg-transitions);
			}
			@if $hover-border {
				border-color:$hover-border $_hover-important;
				$transitions : add(border-color, $transitions);
			}
		}
	}

	// Apply transitions if enabled ----------------------------------------------

	$transitions : remove-duplicates($transitions);

	@if $add-transitions {
		@if length($transitions) {
			@include transition($transitions);
		}
		@if length($svg-transitions) {
			svg { @include transition($svg-transitions); }
		}
	}

}
