#recent-news-grid {
  --headshot-size: 180px;

  margin-bottom: $gap3;

  // Variants (declared as top-level class)
  &.white-bg {
    article {
      background-color: $white;
    }
  }


  h3 {
    text-align: center;
  }

  .news-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gap2;
    align-items: stretch;
  }

  section {
    display: flex;
    flex-direction: column;
    background-color: $blue1;
    color: $white;
    position: relative;

    figure {
      background-color: $grey;

      img {
        display: block;
        margin: -$gap auto 0;
        width: var(--headshot-size);
        height: var(--headshot-size);
      }
    }

    h4 {
      display: block;
      padding: $gap $gap2;
      background-color: $green;
      color: inherit;
      text-transform: uppercase;
      text-align: center;
      margin: 0;
      width: 100%;
    }

    p {
      padding: $gap2;

      svg {
        display: block;
        margin: $gap2 auto 0;
        max-width: 200px;
      }
    }
  }

  article {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: $grey;

    header {
      text-transform: uppercase;
      background-color: $blue1;
      color: $blue2;
      padding: $gap;
      letter-spacing: 1px;
      text-align: center;
      font-weight: 600;
    }

    .detail-wrap {
      display: flex;
      flex-direction: column;
      gap: $gap;
      padding: $gap2;
      height: 100%;

      img {
        width: 100%;
        object-fit: cover;
        object-position: center;
      }

      h2 {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.2;
        min-height: 2.4em;
        vertical-align: middle;
        text-transform: none;
      }

      p {
        &:last-child:has(> a) {
          margin-top: auto;
          text-align: center;
        }
      }
    }
  }

  @media (min-width: $small-medium) {
    .news-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (min-width: $large) {
    .news-container {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
  }
}


// Page specific styles
body#the-logbook-loan-guru {
  // Override conflicting styles from other pages
  #content article + article {
    margin-top: 0;
  }
  content-aside #content {
    h2 {
      margin: 0;
    }
    p {
      margin-bottom: 0;
    }
  }

  #recent-news-grid {
    .news-container {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    section {
      grid-column: -1/1;
    }

    @media (min-width: $large) {
      --headshot-size: 300px;

      section {
        margin-top: $gap4;

        figure {
          // Positioning
          order: 3;
          align-self: end;
          margin-top: calc(var(--headshot-size) * -1);

          // Styles overrides
          background-color: transparent;
          padding: 0 $gap2 0 0;
          height: 100%;

          img {
            margin: 0;
          }
        }

        h4 {
          text-align: left;
          padding-right: calc(var(--headshot-size) + #{$gap2});
        }

        p {
          padding-right: calc(var(--headshot-size) + #{$gap2});
        }
      }
    }
  }
}
