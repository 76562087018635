@function var($args...) {

	$custom-property : ();
	$fallback : null;
	$variables : ();
	$suffix : '';

	// List valid custom properties names
	@each $value in $args {
		@if is-string($value) and not is-number($value) {
			$value : string-replace($value, ' ');
			$variables : append($variables, string-replace($value, '--'));
			$suffix : $suffix + ')';
		} @else {
			$fallback : $value;
		}
	}

	@if length($variables) > 0 {
		@for $i from 1 through length($variables) {

			$variable : nth($variables, $i);
			$last : $i == length($variables);

			@if $last and map-has-keys($custom-properties, $variable) {
				$fallback : map-get($custom-properties, $variable);
			}

			$custom-property : append($custom-property, unquote('var(--' + $variable + ','));

		}
	}

	@if not is-null($fallback) {
		$custom-property : $custom-property + ' ' + $fallback;
	}

	$result : to-string($custom-property) + $suffix;

	$result : string-replace($result, '(var', 'var');
	$result : string-replace($result, ',)', ')');
	$result : string-replace($result, ',))', ')');

	// If the user is passing in variables within variables using the native syntax,
	// This function will render out a looped mess. The following does some checks,
	// and cleans it up. This hasn't been tested on complex variables like gradients,
	// calcs, or anything that isn't a predefined customer-properties;
	@if str-index($result, '--var(') {
		$_result : string-replace($result, 'var(');
		$_result : string-replace($_result, $suffix + ')');
		$_result : string-remove-whitespace($_result);
		$_fallback : null;
		$_variables : ();
		@each $value in string-explode($_result, ',', comma) {
			@if string-starts-with($value, '--') {
				$_variables : append($_variables, $value, comma);
			} @else {
				$_fallback : $value;
			}
		}

		$result : unquote(var($_variables...));

		@if ( not is-null($_fallback)) {
			// When using native syntax, that has var fallbacks with vars, a
			// given fallback is ignored. Either don't use nested vars where the
			// fallback is important, or define a fallback before calling it this way.
			// Ultimately this is almost never going to happen.
			// TODO Inject the fallback so as to not break the syntax. Data is coreect, syntax isn't.
			// $result : $result + $_fallback;
		}

		@return $result;
	}

	@return unquote($result);

}
