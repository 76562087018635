#bad-credit-logbook-loan{
    content-aside{
        hr{
            width: 100%;
            margin: 24px 0;
        }
        #content{
            .content-body{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                ul{
                    margin: 0px 32px 16px 32px;
                    list-style: none;
                    li{
                        position: relative;
                        &:before{
                            content: "";
                            display: block;
                            position: absolute;
                            height: 6px;
                            width: 6px;
                            background-color: $green;
                            border-radius: 50%;
                            left: -24px;
                            top: calc(50% - 3px);
                        }
                    }
                }
                .themed-h2{
                    &:first-of-type{
                        margin-top: 0;
                    }
                    &:last-of-type{
                        width: 100%;
                        text-align: center;
                        & ~ p{
                            text-align: center;
                            width: 100%;
                            a{
                                color: $green;
                            }
                        }
                    }
                }
            }
            p{
                width: 100%;
            }
            .twoCol{
                width: 50%;
                padding-right: 16px;
                & + .twoCol{
                    padding-left: 16px;
                    padding-right: 0px;
                }
            }
            .threeCol{
                width: 33.3%;
                padding-right: 8px;
                & + .threeCol{
                    padding-left: 8px;
                    padding-right: 8px;
                    & + .threeCol{
                        padding-left: 8px;
                        padding-right: 0px;
                    }
                }
            }
            @media(max-width: $medium){
                .twoCol, .threeCol{
                    width: 100%;
                    padding-right: 0;
                    & + .twoCol{
                        padding-left: 0px;
                        padding-right: 0px;
                    }
                    & + .threeCol{
                        padding-left: 0px;
                        padding-right: 0px;
                        & + .threeCol{
                            padding-left: 0px;
                            padding-right: 0px;
                        }
                    }
                }
            }
        }
    }
}
