section.generic-styles {
  @extend .content-block;
  margin-bottom: 48px;

  // NOTE: Missing from .content-block
  ul {
    margin-bottom: 1em;
  }

  &.align-center {
    text-align: center;
  }

  div {
    &::-webkit-scrollbar {
        transform: rotateX(180deg);
        width: 10px;
        position: relative;
    }
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        position: relative;
        transform: rotateX(180deg);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        background: $green;
        position: relative;
        transform: rotateX(180deg);
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    h2 {
        font-size: 28px;
        font-weight: 500;
        color: $blue2;
        margin: 0 0 32px 0;
        text-align: center;

        svg {
            height: 1em;
            vertical-align: middle;
            max-width: 100%;
        }
    }

    // Text styles
    em {
        color: $blue2;
        font-style: normal;
        font-weight: 700;
    }
}

  table {
    border-spacing: 8px;
    font-size: 14px;
    width: 100%;
    margin: 2em 0;

    img {
        max-width: 300px;
    }
    svg {
        max-width: 100%;
    }

    thead {
        th {
            text-align: center;
            text-transform: uppercase;
            background-color: $white;
            font-size: 1.14285em; // ~16px
        }
    }

    tbody {
        background-color: transparent;

        // Highlight first row
        tr:first-child {
            td {
                background-color: #97c21f;
                font-size: 1.25em;  // ~17.5px
                color: $white;
            }
        }

        td {
            text-align: center;
            color: $blue1;
            padding: 8px;
            background-color: $grey;

            &:nth-child(2) {
                background-color: #f6f8ec;
            }
            &:nth-child(3) {
                background-color: #ebf5fa;
            }

            p {
                font-size: inherit;
                margin-bottom: 0.5em;
            }
        }
    }
}
}
