#nhs-discount{
    .content-body{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        h2, p{
            width: 100%;
        }
        ul{
            margin: 0 32px;
            &:first-of-type{
                margin: 0 2.5%;
                width: 45%;
            }
        }
        ol{
            margin: 0 32px;
        }
        .themed-h2{
            &:first-of-type{
                margin-top: 18px;
                @media(min-width: 1051px){
                    width: 51%;
                }
            }
            &:last-of-type{
                width: 100%;
                text-align: center;
                & ~ p{
                    text-align: center;
                    width: 100%;
                    a{
                        color: $green;
                    }
                }
            }
        }
        .ooh-service{
            color: white;
            background-color: $blue1;
            width: 45%;
            padding: 32px;
            margin-top: -72px;
            .themed-h2{
                margin-top: 0;
                & + p {
                    & + p {
                        color: $green;
                        font-weight: bold;
                        margin-bottom: 0;
                        a{
                            color: $green;
                        }
                    }
                }
            }
            &:last-of-type{
                h2, p{
                    text-align: left;
                }
            }
        }
        @media( max-width: 1050px){
            ul:first-of-type,
            .ooh-service{
                width: 100%;
            }
            .ooh-service{
                margin-top: 12px;
            }
        }
        p{
            &:last-of-type{
                text-align: left !important;
                margin-top: 16px;
            }
        }
    }
}
