@mixin show-breakpoints($sudo : before) {
	@media screen {
		@if $show-breakpoints {
		  &:#{$sudo} {
		    content:"\276F  #{add-unit($min)}";
		    background-color:darken($red, 20);
		    bottom:0;
		    right:0;
		    font-family:Arial;
		    padding:4px $gap0;
		    @include transition(background-color color);
		    pointer-events:none;
		    position:fixed;
		    z-index:9999;
		    font-size:12px;
		    line-height:16px;
		    color:white;
		    text-align:center;

				@include break($min, '<') {
					content:"min < #{add-unit($min)}";
					background-color:$red;
					color:colour-contrast($red);
				}

				@for $i from 1 through length($breakpoints) {

					$name : nth(nth($breakpoints, $i), 1);
					$max : nth(nth($breakpoints, $i), 2);
					$min : if($i - 1 > 0, nth(nth($breakpoints, $i - 1), 2), null);

		      $random-color: random-colour();

					@if (not is-null($min)) {
			      @include break($min, '>') {
			        content:"#{$name} #{$min} - #{$max}";
			        background-color:$random-color;
			        color:colour-contrast($random-color);
			      }
					}
		    }

				@include break($max, '>') {
					content:"max #{add-unit($max)} >";
					background-color:$green;
					color:colour-contrast($green);
				}

		  }
		}
	}
}
