////////////////////////////////////////////////////////////////////////////////
// Tabs
////////////////////////////////////////////////////////////////////////////////

tabs-section {

  display: none;
  justify-content: space-between;
  border-bottom: 8px solid $blue2;

  // Tab Body ==================================================================

  tab-body{
    flex-grow: 1;
    padding: $gap;
    line-height: 20px;
    font-weight: 600;
    color: $blue1;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    letter-spacing:3px;

    span{
      @include size(30);
      @include border-radius(15);
      display: inline-block;
      color: $white;
      background: $blue1;
      text-align: center;
      letter-spacing:0;
      line-height: 20px;
      padding-top: 5px;
      margin-right: $gap;
    }

    &.active{
      color: $white;
      background: $blue2;

      span{
        color: $blue2;
        background: $white;
      }
    }

  }

  @media(min-width: $medium){
    display: flex;


  }

}
