section#how-it-works,
section.lp-section.howItWorks {
    @extend .content-block;
    padding: 0 $gap $gap;
    background-color: $grey;
    margin-bottom: 48px;

    h3 {
        color: $blue2;
        text-align: left;
    }

    ul {
        text-align: inherit;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;

        li {
            position: relative;
            margin: 0.5em 0 0.5em 1em;

            &:before {
                content: '\2022';
                color: $green;
                position: absolute;
                top: -0.4em;
                font-size: 2em;
                left: -0.5em;
            }
        }
    }

    // Overrides
    .col-layout {
        width: 100%;
        counter-reset: element-counter;
        padding-top: 0;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 1px;
            top: $gap3;
            left: $gap2;
            bottom: 0;
            background-color: $blue2;
        }

        &>div {
            width: 100%;
            padding: $gap3 24px 0 90px;
            position: relative;
            counter-increment: element-counter;

            &:before,
            &:after{
                display: block;
                position: absolute;
                height: 64px;
                width: 64px;
                left: 0;
                top: $gap2;
            }

            &:before {
                content: "";
                border-radius: 50%;
                background-color: $blue2;
            }
            &:after {
                content: counter(element-counter);
                font-size: 32px;
                line-height: 2;
                color: white;
                text-align: center;
            }

            &:nth-of-type(2n) {
                &:before {
                    background-color: $green;
                }
            }
            &:nth-of-type(3n) {
                &:before {
                    background-color: $blue1;
                }
            }
            &:last-of-type {
                border-top: 0px solid $blue1;
            }

            p {
                padding-left: 60px;
                text-align: center;

                span {
                    display: inline-block;
                    white-space: nowrap;
                }
            }
        }
    }

    svg,
    img {
        max-width: 500px;
        margin: 0 auto 32px;
        display: block;
        padding-top: 32px;
    }

    h2 {
        text-align: center;
        font-size: 20px;
        margin: 0;
    }

    @media (min-width: $medium) {
        padding: 0 $gap2 $gap2;

        .col-layout {
            flex-wrap: nowrap;
            padding-top: $gap4;

            &:before {
                height: 1px;
                width: 80%;
                top: $gap4;
                left: 10%;
                right: 10%;
            }
            &>div {
                padding: $gap3 24px 0;
                &:before,
                &:after {
                    top: -32px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                p {
                    padding-left: 0;
                }
            }
        }
    }
}
