////////////////////////////////////////////////////////////////////////////////
/// Cast to number                                                    #to-number
////////////////////////////////////////////////////////////////////////////////

/// Cast a value to a number if possible or return 0
/// @author Hugo Giraudel
/// @link https://www.sassmeister.com/gist/9fa19d254864f33d4a80
/// @group helpers-casts
/// @param {string} $value
/// @return {number}

// @function to-number($value) {
//   @if type-of($value) == 'number' {
//     @return $value;
//   } @else if type-of($value) != 'string' {
//     $_: log('Value for `to-number` should be a number or a string.');
//   }
//
//   $result: 0;
//   $digits: 0;
//   $minus: str-slice($value, 1, 1) == '-';
//   $numbers: ('0': 0, '1': 1, '2': 2, '3': 3, '4': 4, '5': 5, '6': 6, '7': 7, '8': 8, '9': 9);
//
//   @for $i from if($minus, 2, 1) through str-length($value) {
//     $character: str-slice($value, $i, $i);
//
//     @if not (index(map-keys($numbers), $character) or $character == '.') {
//       @return to-length(if($minus, -$result, $result), str-slice($value, $i))
//     }
//
//     @if $character == '.' {
//       $digits: 1;
//     } @else if $digits == 0 {
//       $result: $result * 10 + map-get($numbers, $character);
//     } @else {
//       $digits: $digits * 10;
//       $result: $result + map-get($numbers, $character) / $digits;
//     }
//   }
//
//   @return if($minus, -$result, $result);;
// }
//
$sc-constants: (
  'decimal-space': ('0', '1', '2', '3', '4', '5', '6', '7', '8', '9'),
  'unit': ('px', 'cm', 'mm', '%', 'ch', 'pc', 'in', 'em', 'rem', 'pt', 'ex', 'vw', 'vh', 'vmin', 'vmax', 'ms', 's', 'deg', 'rad', 'grad', 'turn', 'Hz', 'kHz', 'dpi', 'dpcm', 'dppx'),
  'unit-value': (1px, 1cm, 1mm, 1%, 1ch, 1pc, 1in, 1em, 1rem, 1pt, 1ex, 1vw, 1vh, 1vmin, 1vmax, 1ms, 1s, 1deg, 1rad, 1grad, 1turn, 1Hz, 1kHz, 1dpi, 1dpcm, 1dppx)
);

/// Default return value for the `to-number(..)` function when running in
/// non-strict mode.

/// @type Number
$sc-non-strict-default-number: 0 !default;

/// Default return value for the `to-color(..)` function when running in
/// non-strict mode.

/// @type Number
$sc-non-strict-default-color: transparent !default;
/// Internal map for dynamically accessing default values for non-strict mode.
/// @access private
/// @type Map
$sc-non-strict-defaults: (
  'number': $sc-non-strict-default-number,
  'color': $sc-non-strict-default-color,
);

/// Helper function to throw when running in strict mode, or warn and return
/// default value for expected type when running in non-strict mode.
/// @param {*} $value
/// @param {String} $expected-type

@function _sc-throw($value, $expected-type) {
  $return-value: map-get($sc-non-strict-defaults, $expected-type);
  @return $return-value;
}

/// Tries to find a unit that would match a CSS length
/// @access private
/// @param {Number} $number - number
/// @param {String}   $unit - potential unit
/// @return {Number} length (0 if cast failed)

@function _sc-unit($number, $unit) {
  $units: map-get($sc-constants, 'unit-value');
  $index: index(map-get($sc-constants, 'unit'), $unit);

  @if not $index {
    @return _sc-throw($number, 'number');
  }

  @return ($number * nth($units, $index));
}


/// Cast a value to a number if possible or return 0
///
/// @author Hugo Giraudel
///
/// @link https://github.com/HugoGiraudel/SassyCast
///

///
/// @param {string} $value
///
/// @return {number}

@function to-number($value) {
  $type: type-of($value);

  // If the value is already a number, we can safely return it.
  @if ($type == 'number') {
    @return $value;
  }

  // If the value is the `true` boolean, we return 1.
  @if ($value == true)  {
    @return 1;
  }

  // If the value is the `false` boolean, we return 0.
  @if ($value == false) {
    @return 0;
  }

  // If the type is not a string, there is no way we can convert it to a number,
  // so we warn and return the default number value (or throw in strict mode).
  @if ($type != 'string') {
    @return _sc-throw($value, 'number');
  }

  // At this point we have discarded all the edge cases so we can start trying
  // to cast the value into a number.
  $pointer: 1;
  $result: 0;
  $first-character: str-slice($value, $pointer, $pointer);
  $allowed-first-character: join(('-', '.'), map-get($sc-constants, 'decimal-space'));

  // We perform an early check for errors. If value starts with neither a number
  // nor a minus sign, it cannot be casted to a number. Therefore we warn and
  // return the default number value (or throw in strict mode).
  @if not index($allowed-first-character, $first-character) {
    @return _sc-throw($value, 'number');
  }

  // In case the value starts with a dot, we assume it is a float and pad it
  // with a zero.
  @if ($first-character == '.') {
    $value: '0' + $value;
  }

  // We find the integer part of the value.
  $find-integer: find-integer($value, $pointer);
  $pointer: nth($find-integer, 1);
  $result:  nth($find-integer, 2);

  // If there still is a dot, it means we have to check for the digits part of
  // the value.
  @if (str-slice($value, $pointer, $pointer) == '.') {
    $find-digits: find-digits($value, $pointer);
    $pointer: nth($find-digits, 1);
    $digits:  nth($find-digits, 2);
    $result: ($result + $digits);
  }

  // If the first character is a minus symbol, it means the number is negative
  // so we can multiply it per -1.
  @if ($first-character == '-') {
    $result: ($result * -1);
  }

  // If we are still not done with the evaluation, it means there could be a CSS
  // unit, so we check for it.
  @if ($pointer <= str-length($value)) {
    $result: _sc-unit($result, str-slice($value, $pointer));
  }

  @return $result;
}

@function to-number2($value) {

	$type: type-of($value);

	// If the value is a number, job done.
  @if ($type == 'number') { @return $value; }

	// True booleans return 1.
  @if ($value == true) { @return 1; }

	// False booleans return 0.
  @if ($value == false) { @return 0; }

	// If the first character is a dot, suffix a 0 to save pain later on.
	@if (str-slice($value, 1, 1) == ".") {
		$value : 0 + $value;
	}

	// Remove white space.
	$value : string-replace($value, ' ');

	// Determine if the value is a negative number.
	$minus : str-slice($value, 1, 1) == '-';

	$result : 0;
	$unit : '';
	$decimal-position : null;
	$numbers : '0' '1' '2' '3' '4' '5' '6' '7' '8' '9';
	$units: ( 'px': 0px, 'cm': 0cm, 'mm': 0mm, '%': 0%, 'ch': 0ch, 'in': 0in,
	'em': 0em, 'rem': 0rem, 'pt': 0pt, 'pc': 0pc, 'ex': 0ex, 'vw': 0vw, 'vh': 0vh,
	'vmin': 0vmin, 'vmax': 0vmax, 'deg': 0deg, 'turn': 0turn, 'rad': 0rad,
	'grad': 0grad, 's': 0s, 'ms': 0ms, 'Hz': 0Hz, 'kHz': 0kHz, 'dppx': 0dppx,
	'dpcm': 0dpcm, 'dpi': 0dpi );

	// Loop through every character in the value.
	@for $i from 1 through str_length($value) {

		// Define the character.
		$character : str-slice($value, $i, $i);

		// Ignore minus symbols.
		@if $character != '-' {

			// If the character matches a number, add it to the results,
			// with a little magic to keep the string a number.
			@if contains($numbers, $character) {
				$result: $result * 10 + (index($numbers, $character) - 1);
			}
			// If there's a dot, log it's position so we can put it back later on.
			@elseif $character == '.' {
				$decimal-position: $i - 1;
			}
			// Any other characters are assumed to be part of the unit if given.
			@else {
				$unit : $unit + $character;
			}

		}
	}

	// Put the decimal point back.
	@if not is-null($decimal-position) {
		$n : 1;
		@for $i from 1 through ($decimal-position - if($minus, 2, 1)) {
      $n : $n * 10;
    }
		$result : round($result * $n) / $n;
	}

	// Adjust the number to a negative to reinstate the minus symbol.
	@if ($minus) {
		$result: ($result * -1);
	}

	// Using the list of units and their keys, apply a unit to the results.
	@if map-has-key($units, $unit) {
		$result : map-get($units, $unit) + $result;
	}

	@debug $decimal-position;
	@debug $value;
	@debug $result;
	@debug type-of($result);

	// Return the value result

	@if type-of($result) == 'number' {
		@return $result;
	} @else {
		@debug 'Unabled to cast the given value into a number: ' + $value;
		@return $value;
	}

}
