.locations{
    .logo-bar{
        position: relative;
    }
    .header-flag {
        max-width: 54px;
        height: auto;
        position: absolute;
        overflow: hidden;
        border-radius: 50%;
        z-index: -1;
        top: 20px;
        left: 115px;
        img{
           display: block;
           margin: 0 auto;
           height: auto;
           width: 150%;
           margin: 0 0 0 -13px;
       }
        @media(max-width: 840px){
            top: 30px;
            left: 15px;
            max-width: 34px;
            img{
                margin: 0 0 0 -11px;

            }
        }
        @media(max-width: 480px){
            top:85px;
            left: 110px;
            max-width: 40px;
            img{
                margin: 0 0 0 -11px;

            }
        }
    }


    main{
        #content{
            overflow: visible;
        }
        & > .location-banner{
        height: 520px;
        position: absolute;
        width: 95vw;
        left: 0;
        right: 0;
        background-repeat: no-repeat;
        box-shadow: 150px 100px 100px 0 #fff inset, -150px -100px 100px 0 #fff inset;
        margin-left: calc(50% - 47.5vw);
        margin-right: calc(50% - 47.5vw);
        background-size: cover;
        h2 {
            text-transform: none;;
            background-color: white;
            margin: 0 auto;
            font-size: 28px;
            position: relative;
            padding: 10px 10px 10px 90px;
            width: fit-content;
            vertical-align: middle;
            margin-top: 190px;
        }
        .banner-flag{
            position: absolute;
            left: 0;
            bottom: 0;
            max-height: 48px;
            width: 79px;
        }
    }
    .lp-section {
        padding: $gap2 $gap;

        &:first-of-type {
            h3 {
                color: $blue2;
            }
            p {
                &:last-of-type {
                    font-weight: 600;
                }
            }
        }
        // "odd" but not first
        &:nth-of-type(2n+3) {
            background-color: $green3;

            svg {
                fill: $green;
            }
        }
        &:nth-child(even) {
            background-color: $blue3;

            svg {
                fill: $blue2;
            }
        }
        &:nth-child(odd) {
            background-color: $green3;

            svg {
                fill: $green;
            }
        }
        &:nth-of-type(n+3) {
            margin-top: $gap;
        }

        header {
            text-align: center;

            svg {
                width: 80px;
                height: 80px;
                margin-bottom: $gap;

                & + h3 {
                    font-size: 18px;
                }
            }
            h2, h3 {
                line-height: 1.4;
                margin-bottom: $gap;
            }
            h2 {
                font-weight: 100;
                text-transform: uppercase;
            }
        }
        h3 {
            text-align: center;
        }
        p {
            text-align: justify;
            margin-bottom: 1em;
            line-height: 1.4;

            &:first-of-type {
                font-weight: 600;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    .cta-button {
        padding: $gap $gap 0;
        text-align: center;
    }

    // Responsive styles
    @media(min-width: $small-medium) {
        .lp-section {
            padding: $gap2;
        }
        .cta-button {
            padding: $gap2 $gap2 0;
        }
    }
    @media(min-width: $medium) {
        .lp-section {
            padding: $gap4;

            &:nth-of-type(n+3) {
                margin-top: $gap2;
            }
            &:nth-of-type(even){
                margin-top: $gap2;
            }
        }
        .cta-button {
            padding: $gap4 $gap4 0;

            a {
                font-size: 24px;
            }
        }
    }
}
    .spacer{
        padding: 200px 0;
    }
    @media(max-width: 768px){
        main {
            overflow:hidden;
            & > .location-banner{
                width: 140vw;
                box-shadow: 50px 10px 10px 0 #fff inset, -50px -10px 10px 0 #fff inset;
                margin-left: calc(50% - 70vw);
                margin-right: calc(50% - 70vw);
                height: 240px;
                h2 {
                    margin-top: 60px;
                }
            }
            .spacer{
                padding: 80px 0;
            }
        }
    }
}
