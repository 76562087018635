/// Use calc to apply a reponsive fontsize and lineheight
///
/// @author Mark Notton
///
/// @link https://github.com/seaneking/postcss-responsive-type/blob/master/test/fixtures/default.expected.css
/// @link https://css-tricks.com/snippets/css/fluid-typography/
///
/// @param {Arglist} $settings - The following parameters need to added in this order
///
/// @param {number} $max  - At the largest breakpoint, this size will be used to fallback on.
/// @param {number} $min  - At the small breakpoint, this size will be used to fallback on. default $font-size;
/// @param {number} $max-width - breakpoint to stop the responsive font-size. Default $max breakpoint
/// @param {number} $min-width - breakpoint to start the responsive font-size. Default $large breakpoint
/// @param {bool}   $important - Adds an important flag
///
/// @example scss - Usage
///   @include responsive-fontsize(28);
///   @include responsive-fontsize(28, 20, 1400, 720);

@mixin responsive-fontsize($args...) {

	$defaults : (
		max       : null,
		min       : null,
		max-width : $max,
		min-width : $small
	);

	$settings  : get-settings($defaults, $args...);

	$max       : remove-unit(map-get($settings, max));
	$min       : remove-unit(map-get($settings, min));
	$max-width : add-unit(map-get($settings, max-width));
	$min-width : add-unit(map-get($settings, min-width));
	$important : map-get($settings, important);

	$max : if(is-null($max), ($font-size * 3), $max);

	// If $min is null, set it to half the size of $max
	$min : if(is-null($min), ($max / 2), $min);

	// If $min is smaller than the default font-size, we it to the default $font-size
	$min : if($min < $font-size, $font-size, $min);

	// If $min is bigger than max, set it to $max
	$min : if($min > $max, $max, $min);

	$max : add-unit($max);
	$min : add-unit($min);

	$u1: unit($min-width);
	$u2: unit($max-width);
	$u3: unit($min);
	$u4: unit($max);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {

		// If $min and $max are the same, just output that standard font-size
		@if $max == $min {
			font-size: $max $important;
		} @else {

			font-size: $min $important;

	    @media screen and (min-width: $min-width) {
	      font-size: calc(#{$min} + #{remove-unit($max - $min)} * ((100vw - #{$min-width}) / #{remove-unit($max-width - $min-width)})) $important;
	    }
	    @media screen and (min-width: $max-width) {
	      font-size: $max $important;
	    }
		}
  }
}
