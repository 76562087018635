#how-it-works.premium,
#premium {
    svg {
        fill: #00A7E7;
        &#how-it-works{
            margin: 50px auto 0;
            width: 150px;
            height: 150px;
            align-items: center;
            display: inline-block;
            fill: inherit;
        }
    }

    navigation-bar {
        background-color: #00A7E7;

        & > a {
            background-color: inherit;
        }

        ul li a:hover {
            background: darken(#00A7E7, 10);
        }
    }

    p a:hover {
        color: #00A7E7;
    }

    main {
        #content {
            position: relative;
            width: 100%;
            min-height: 500px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            padding: 25px 10px;

            .page-title {
                margin: 50px 18px 0;
                font-size: 40px;
                font-weight: 300;
                text-align: center;
                color: #030404;
            }
            .strapline {
                display: block;
                margin: 24px;
                font-size: 20px;
                font-weight: 300;
                color: #00A7E7;
                text-align: center;
            }

            > svg {
                &:first-of-type {
                    display: none;
                    width: 700px;
                }

                &:last-of-type {
                    display: block;
                    width: 300px;
                }
            }

            > p {
                font-size: 26px;
                text-transform: uppercase;
                letter-spacing: 3px;
                text-align: center;

                span {
                    font-weight: 600;
                }
            }

            input[type="submit"],
            button[type="submit"] {
                margin-top: 30px;
                font-size: 24px;
            }

            contact-details {
                padding: 0;
                margin-bottom: 2em;
            }

            .social-icon {
                display: block;
                fill: $blue2;

                svg {
                    width: 25px;
                    height: 25px;
                    transition: fill 300ms;
                }

                &:active,
                &:focus,
                &:hover {
                    svg {
                        fill: $blue1;
                    }
                }
            }

            nav.header-nav {
                position: absolute;
                top: 15px;
                right: 8px;
                display: flex;
                align-items: center;

                > a {
                    display: inline-block;
                    line-height: 0;

                    & + a {
                        margin-left: 5px;
                    }
                }

                .menu-trigger {
                    width: 30px;
                    height: 30px;
                    margin-left: 20px;

                    svg {
                        width: 30px;
                        height: 30px;
                        fill: $green;
                    }
                }
            }

            h3 {
                text-align: center;

                a {
                    color: $green;

                    &:hover {
                        color: $blue2;
                    }
                }
            }

            p {
                font-size: 15px;
            }
        }

        .car-examples-block{
            background-color: #f7f7f7;
            margin-top: 32px;
            padding: 64px 32px;
            text-align: center;
            h2 {
                font-size: 36px;
                font-weight: 300;
                span{
                    font-weight: bold;
                    color: #00A7E7;
                    display: block;
                    font-size: 20px;
                    margin-top: 16px;

                }
                @include break($small-medium, '<') {
                    font-size: 26px;
                    span{
                        font-size: 18px;
                    }
                }
            }
            h3{
                font-weight: 300;
                color: black;
                margin-bottom: $gap2;
                letter-spacing: 1px;
                font-size: 32px;
                text-transform: uppercase;
                @include break($small-medium, '<') {
                    font-size: 26px;
                }
            }
            p{
                color: black;
            }
            .block-content{
                display: block;
                column-count: 2;
                margin: 32px auto;
                p{
                    text-align: left;
                    padding: 8px 16px;
                }
                @include break($small-medium, '<') {
                    display: block;
                    column-count: 1;
                    height: 100%;
                    margin-bottom: 32px;
                    p{
                        width: 100%;
                        padding: 8px 0px;
                    }
                }
            }
            .details-modal{
                display: block;
                h3{
                    text-align: center;
                    color: #3ab4f0;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
            @include break($small-medium, '<') {
                padding: 32px;
            }
            .brand-link{
                text-transform: uppercase;
                padding-right: 32px;
                span{
                    font-weight: 300;
                    text-transform: uppercase;
                }
                @include break($small-medium, '<') {
                    font-size: 16px;
                    span{
                        display: block;
                    }
                }
            }
        }


        hiw-blocks {
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            justify-content: space-between;

            .section-content {
                display: block;
                width: 100%;
            }

            .block-content {
                display: block;
                background: #fff;
                padding: 48px 32px;

                @media ( max-width: 1200px ) {
					flex-basis		: 100%;
					margin-bottom	: 32px;
                }
                @media ( min-width: 1200px ) {
					flex	: 0 1 30%;
					margin	: 0 auto;
                }

                p {
                    color: black;
                    font-size: 15px;
                    font-weight: lighter;
                }

                h3 {
                    color: #00A7E7;
                }
            }

            .button.apply-now-btn {
				align-self	: center;
				margin-top	: 32px;
            }
        }

        usp-blocks {
            svg {
                fill: #000;
                margin-bottom: 16px;
            }

            h3 {
                color: #00A7E7;
                letter-spacing: 1px;
            }
        }

        loan-features,
        representative-example {
            background: #00A7E7;
            margin-bottom: 48px;

            svg {
                fill: #fff;
            }

            h5,
            p {
                color: #fff;
            }
        }

        .button {
            background: #000;
            &.apply-now-btn{
                margin: 0 auto;
            }
            @media(min-width: 521px){
                &.how-it-works-btn{
                    margin-right: 24px;
                }
            }
            &:hover {
                background-color: #00A7E7 !important;
                border-color: #00A7E7 !important;
            }
        }

        input-fields > div.inputs a {
            color: #00A7E7;
        }

        unique-selling-points {
            background: #f6f6f6;
            padding: 64px 32px;
            @media(max-width: 520px){
                padding: 32px;
            }
        }
        loan-features{
            margin-bottom: 0;
        }
    }

    footer {
        background: #f7f8fa;

        p {
            color: #000;
        }

        > div ul {
            li {
                color: #000;

                a {
                    color: #000;
                }

                &:first-of-type {
                    color: #00A7E7;
                }
            }
        }
    }
    @include break(520px, '<') {
        main {
            #content {
                > svg {
                    &:last-of-type {
                        width: 270px;
                        // transform: translateX(-25px) translateY(-15px);
                    }
                }
            }
        }
        unique-selling-points{
            .button{
                width: 100%;
                margin: 24px 0 0 0;
            }
        }
    }
    @include break($medium, '>') {
        main {
            #content {
                padding: 10px 100px 50px;

                application-form {
                    .inputs {
                        // padding: 10px 0;
                        // max-width: 48%;
                        // margin-right: 1%;
                        // text-align: right;

                        // &:first-of-type {}

                        // &:last-of-type {
                        //     text-align: left;
                        //     margin-right: auto;
                        //     margin-left: 1%;
                        // }
                    }

                    @include break($medium, '>') {
                        .checkboxes {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            justify-content: center;
                            .inputs{
                                margin: 0 0 0 1%;
                                padding: 10px 0;
                                &:first-of-type{
                                    margin: 0 1.3%;
                                }
                            }
                        }
                    }
                }

                > svg {
                    &:first-of-type {
                        display: block;
                    }

                    &:last-of-type {
                        display: none;
                    }
                }
            }
        }
    }
    @include break($large, '>') {
        main {
            #content {
                input[type="submit"],
                button[type="submit"] {
                    margin-top: 50px;
                }
            }
        }
    }
}

#how-it-works.premium {
    main {
        representative-example {
            margin-bottom: 0;
        }
    }
}


.loyc-premium-logo{
    margin: 32px 0;
    display: block;
    content:url("/assets/images/loanonyourcar-premium-logo.svg");
    width: 340px;
}
footer{
    .loyc-premium-logo{
        width: 340px;
        margin: 0 0 20px 0;
    }
}
@include break($medium, '<'){
    site-wrapper > header{
        a{
          &:last-of-type{
            display:block !important;
          }
        }
    }
    .loyc-premium-logo{
        content:url("/assets/images/loanonyourcar-premium-mobile-logo.svg");
        width: 250px;
        margin: 32px 0;
    }
    footer{
        .loyc-premium-logo{
            width: 100%;
            max-width: 460px;
        }
    }
}
