////////////////////////////////////////////////////////////////////////////////
// Navigation Bar
////////////////////////////////////////////////////////////////////////////////

navigation-bar {
  display: block;
  position: absolute;
  top: 54px;
  right: 0px;
  background: $green;
  z-index: 99;
  max-width:350px;
  width:100%;
  height:auto;
  pointer-events: none;
  opacity: 0;
  transition: opacity 300ms;


  @include break($medium, '>') {
    right: 0px;
}

nav {
    position: absolute;
    top: 1px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    background-color: $green;
    max-height: calc(100vh - 54px);
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar { display: none; }
    max-width: 100vw;


    > a {
        display: inline-block;
        line-height: 0;

        & + a {
            margin-left: 5px;
        }
    }
}

  > a{
    position: absolute;
    display: block;
    width: 89px;
    height:56px;
    right: 0px;
    top:-55px;
    line-height: 55px;
    text-align: center;
    color: $green;
    font-size: 55px;
    z-index: 999;
    transition: 300ms;
  }

  ul{
    max-width: 100%;

    li{
      border-bottom: 1px solid rgba($white, 0.6);
      &:last-of-type{ border-bottom: none; }
      a{
        display: block;
        color:$white;
        font-size: 17px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 55px;
        padding:0 25px;
        transition: 300ms;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover{
          background: darken($green, 10%);
        }
        span{
          text-transform: lowercase;
        }
      }
      ul{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        li{
          flex-grow: 1;
          border:none;
          &:first-of-type{
            border-right:1px solid rgba($white, 0.6);
          }
          a{
            font-size: 14px;
            padding:0 5px;
            text-align: center;
          }
        }
      }
    }
  }

  &.open{
    pointer-events: all;
    opacity: 1;

    > a{
      color:$white;
      background: $green;
      &:hover{
        background: darken($green, 10%);
      }
    }
  }
}
