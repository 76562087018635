body#home {
    div.modal {
        // top: unset;
        // right: unset;

        @media (max-width: 768px) {
            // bottom: 30%;
            // left: 0;
            // max-width: 100%;
            .content {
                // padding: 0;
                .further-information {
                    // padding: $gap;
                    div {
                        width: 100%;
                        padding: 0;
                        // margin-bottom: $gap2;
                    }
                    p {
                        min-width: 200px;
                        word-wrap: break-word;
                    }
                }
            }
        }
    }
}

content-aside aside {
    z-index: 9;

    .further-information h3 {
        background: none;
        color: $blue2 !important;
        padding: 0;
    }
}

div.modal {
    position: fixed;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: $grey;
    padding: $gap2;
    margin: 0 $gap;
    border-radius: $border-radius;
    z-index: 999;
    width: calc(100vw - #{$gap2});
    max-width: $small-medium;

    @include break($small-medium) {
        left: 50%;
        transform: translate(-50%, -50%);
    }

    > div {
        padding: 0;
        text-align: center;

        .loyc-logo {
            margin: 0 auto $gap;
            padding: $gap;
            max-width: 100%;
        }
        hr {
            width: 50%;
            margin: $gap2 auto;
        }

        h3,
        a {
            color: $blue2;
        }

        p {
            width: 75%;
            margin: 0 auto $gap;
            font-weight: bold;
        }

        p,
        hr,
        h3 {
            min-width: 300px;
        }

        label {
            margin: 0 $gap;
        }

        input[type="radio"] {
            appearance: checkbox;
        }

        button {
            &#switch-and-save-btn,
            &#vintage-or-prestige-btn {
                display: flex;
                margin: $gap2 auto 0;
                &.hidden {
                    display: none;
                }
            }
        }

        .further-information {
            > div {
                margin-top: $gap2;
                margin-bottom: $gap2;
            }
        }
    }

    .close-modal {
        height: 32px;
        width: 32px;
        display: flex;
        position: absolute;
        right: $gap;
        top: $gap;

        &:hover {
            cursor: pointer;
        }

        &:before,
        &:after {
            position: absolute;
            content: "";
            height: 100%;
            width: 2px;
            background-color: $blue;
        }

        &:before {
            transform: rotate(-45deg);
            left: 15px;
        }

        &:after {
            transform: rotate(45deg);
            right: 15px;
        }
    }

    @media (max-width: 768px) {
        // top: unset;
        // bottom: $gap3;
        // left: 0;
        // max-width: 100%;
        // width: 100%;
        div {
            h3,
            p,
            hr {
                min-width: 200px;
            }
        }
        .content {
            // padding: 0;
            .further-information {
                // padding: $gap;
                div {
                    width: 100%;
                    padding: 0;
                }
            }
        }
    }
}

.overlay.application {
    overflow: hidden;
    z-index: 8;
    background-color: black;
    opacity: 0.5;

    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: none;
    &.active {
        display: block;
    }
}
