// Place holder animation
@mixin placeholder($args...) {

  $defaults : (
    colour : #a9a9a9,
  );

  $settings : get-settings($defaults, $args...);

  $colour       : map-get($settings, colour);
  $important    : map-get($settings, important);
  $placeholders : ":-webkit-input" ":-moz" "-moz" "-ms-input";

  @each $placeholder in $placeholders {
    &:focus:#{$placeholder}-placeholder {
      color:transparent $important; @content;
    }

    &:#{$placeholder}-placeholder {
      color:$colour $important; @include transition(color);
    }
  }

}
