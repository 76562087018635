contact-details{
  display: block;
  text-align:center;
  padding:$gap2;
  text-transform: uppercase;
  margin-bottom:32px;

  p {
    font-size: 20px !important;
    line-height: 1.5 !important;
    margin: 0;
    letter-spacing: $letterspace;

    strong {
      white-space: nowrap;
      letter-spacing: normal;
    }
    a {
      color: $grey1;
    }
  }
  svg{
    fill:$green;
    width:20px;
    height: 20px;
    margin-left: 10px;
    transform: translateY(2px);
  }
}
