$lg-block-height: 400px;

body#how-it-works {
    #content {
        text-align: center;
        padding: $gap2;

        hiw-blocks {
            > div {
                svg {
                    width: 70px;
                    height: 70px;
                    fill: $blue2;
                    margin: $gap2 0;
                }

                h3 {
                    font-size: 18px !important;
                    color: $blue1;
                    font-size: 30px;
                    text-transform: uppercase;
                    letter-spacing: normal;
                    margin-bottom: $gap;
                    line-height: 1.4;
                }

                p {
                    font-size: 16px;
                    line-height: 1.4;
                    margin-bottom: $gap;
                    text-align: justify;

                    &:last-of-type {
                        margin-bottom:0;
                    }
                }
            }
        }

        h2 {
            text-transform: uppercase;
            text-align: center;
            color: $blue2;
            font-size: 30px;
            font-weight: 400;
            letter-spacing: normal;
            margin-top: $gap4;
        }

        h4 {
            text-transform: uppercase;
            text-align: center;
            margin-bottom: $gap4;
        }
    }

    process-blocks {
        display: block;
        margin-top: $gap2;
        width: calc(100% + #{$gap6});
        margin-left: -$gap3;
        user-select: none;


        a {
            color: $green;
            white-space: nowrap;

            &:hover, &:focus, &:active {
                color: lighten($green, 10%);
            }
        }

        > div {
            position: relative;
            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;
            text-align: center;
            position: relative;
            padding: $gap4 $gap2 $gap2;

            &:before {
                content: '';
                position: absolute;
                bottom: -30px;
                left: calc(50% - 150px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 30px 150px 0 150px;
            }

            &:nth-of-type(1) {
                z-index: 1;
                &:before {
                    background: $blue2;
                    border-color: $blue1 transparent transparent transparent;
                }
            }

            &:nth-of-type(2) {
                z-index: 2;
                &:before {
                    background: $blue1;
                    border-color: $blue2 transparent transparent transparent;
                }
            }

            &:nth-of-type(3) {
                z-index: 3;
                &:before {
                    background: $green;
                    border-color: $blue1 transparent transparent transparent;
                }
            }

            &:last-child {
                z-index: 4;
                &:before {
                    display: none;
                }
            }
            a.button {
                margin-bottom: 10px;
            }

            p {
                color: $white;
                font-size: 16px;
                line-height: 22px;

                &.lrg {
                    font-size: 25px;
                    line-height: 35px;
                }
            }

            small {
                color: $white;
                margin-top: 10px;
            }

            > span {
                display: inline-block;
                width: 50px;
                height: 50px;
                line-height: 50px;
                font-size: 30px;
                background: $white;
                border-radius: 50%;
                font-weight: 700;
                margin-bottom: $gap;
            }

            &:nth-of-type(1) {
                background: $blue1;
                z-index: 99;

                > span {
                    color: $blue1;
                }
            }

            &:nth-of-type(2) {
                background: $blue2;
                z-index: 98;

                > span {
                    color: $blue2;
                }
            }

            &:nth-of-type(3) {
                background: $blue1;
                z-index: 97;

                > span {
                    color: $blue1;
                }
            }

            &:last-child {
                background: $green;
                z-index: 96;

                > span {
                    color: $green;
                }
            }
        }
    }

    representative-example {
        margin-top: $gap2;
    }

    @media(min-width: $medium) {
        #content {
            padding: $gap3 $gap3 0;

            hiw-blocks {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                > div {
                    padding: 0 $gap2;
                }
            }
        }

        process-blocks {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;

            > div {
                flex-grow: 1;
                width: 50%;
                min-height: $lg-block-height;
                text-align: center;
                padding: $gap3 $gap2 $gap2 $gap3;
                padding-top: calc(100px + #{$gap2});

                > span {
                    position: absolute;
                    top: 50px;
                    margin: 0;
                    left: 50%;
                    transform: translateX(-25%);
                }

                p {
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -25px;
                    bottom: auto;
                    left: auto;
                    width: 0;
                    height: 100%;
                    border-style: solid;
                    border-width: ($lg-block-height/2) 0 ($lg-block-height/2) 50px;
                }

                &:nth-of-type(1):before {
                    background: $blue2;
                    border-color: transparent transparent transparent $blue1;
                }

                &:nth-of-type(2):before {
                    background: $white;
                    border-color: $blue2 transparent $blue2 $blue2;
                }

                &:nth-of-type(3):before {
                    background: $green;
                    border-color: transparent transparent transparent $blue1;
                }

                &:nth-of-type(4):before {
                    display: none;
                }
            }
        }
    }
    @media(min-width: $large) {
        #content {
            hiw-blocks {
                flex-wrap: nowrap;
            }
        }

        process-blocks {
            min-height: $lg-block-height;

            > div {
                width: 25%;

                &:nth-of-type(2):before {
                    background: $blue1;
                    border-color: transparent transparent transparent $blue2;
                }
            }
        }
    }
}
