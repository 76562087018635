.usp-banner {
  display: flex;
  flex-direction: column;

  max-width: 100%;
  background-color: $blue1;
  color: $blue2;
  text-transform: uppercase;
  font-size: 1.4em;
  font-weight: 700;

  // Mobile specific
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 32px;

  user-select: none;

  // Dynamic flexbox dividers
  &:before, &:after {
    align-self: stretch;
    content: '';
    border-top: 1px solid $white;
    margin: 0 10px;
  }

  .usp-block {
    &:first-child {
      order: -1;
    }
    &:last-child {
      order: 1;
    }
  }

  // Components
  svg {
    height: 52px;
    width: 52px;
  }

  figure {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }

  .usp-block {
    padding: 32px 48px;
  }

  // Responsive
  @media (min-width: $small-medium) {
    padding: 0 48px;
    font-size: 1.8em;
  }
  @media (min-width: $large) {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    // Mobile overrides
    padding: 0;
    width: 100%;
    margin: 0 0 32px;

    // Dynamic flexbox dividers
    &:before, &:after {
      margin: 10px 0;
      border-top: none;
      border-left: 1px solid $white;
    }
  }
}
