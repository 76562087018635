body#switch-and-save content-aside{
    @extend .content-block;

    header {
        margin-bottom: 18px;
        svg {
            height: 140px;
            width: auto;
            margin-bottom: $gap2;
            max-width: 100%;
        }
        h1 {
            text-align: left;
            font-size: 22px;
            margin: 24px 0;
            color: $blue2;
        }
    }

    // Overrides
    div#content{
        h3, p{
            text-align: left;
        }
        h3{
            font-size: 22px;
            margin-top: 36px;
        }
        header{
            text-align: center;
        }
        .video-block{
            display: block;
            margin: 64px 0;
            background-color: $blue1;
            color: white;
            padding: 32px;
            h3{
                color: white;
                margin: 0 0 12px 0;
            }
            iframe{
                width: 100%;
                margin-right: 32px;
                @media(min-width: 320px){
                    height: 185px;
                }
                @media(min-width: 540px){
                    height: 310px;
                }
                @media(min-width: 840px){
                    height: 185px;
                }
                @media(min-width: 1260px){
                    height: 190px;
                }
                @media(min-width: 1400px){
                    height: 220px;
                }
            }
            ul{
                max-width: 100%;
                list-style: none;
                margin: 0;
                li{
                    padding-left: 28px;
                    position: relative;
                    margin: 8px 0;
                    &:before{
                        content: "";
                        border-bottom: 3px solid $green;
                        border-left: 3px solid $green;
                        width: 14px;
                        height: 9px;
                        transform: rotate(-45deg);
                        position: absolute;
                        display: block;
                        left: 0;
                        top: 20%;
                    }
                }
            }
            @media( min-width: 996px){
                display: flex;
                justify-content: space-around;
                height: fit-content;
                align-items: center;
                iframe{
                    width: 48%;
                    margin-right: 32px;
                }
                p{
                    text-align: left;
                }
                div{
                    max-width: 50%;
                }
            }
        }
    }

    .switch-and-save-cta{
        p{
            font-size: 22px;
            text-transform: uppercase;
        }
        svg{
            height: 24px;
            width: 24px;
            margin: 0 8px;
            padding-top: 4px;
            fill: $green;
        }
        a:not(.button){
            color: $blue2;
        }
        iframe{
            padding: 12px;
            background-color: #29235c;
            max-width: 100%;
        }
    }

    .competitors-logo{
        display: flex;
        justify-content: space-around;
        margin: 24px 0;
        flex-wrap: wrap;
        width: 100%;
        img{
            display: flex;
            max-height: 58px;
            height: auto;
            padding: 12px;
            &:first-of-type{
                padding-left: 0;
            }
            &:last-of-type{
                padding-right: 0;
            }
        }
    }
    @media( max-width: $medium){
        .competitors-logo{
            display: flex;
            justify-content: space-around;
            margin: 16px 0;
            flex-wrap: wrap;
            width: 100%;
            img{
                display: flex;
                height: auto;
                max-width: 40%;
                margin: 0 auto;
                padding: 12px;
                max-height: unset;
                &:first-of-type{
                    padding-left: 0;
                }
                &:last-of-type{
                    padding-right: 0;
                }
            }
        }
        div#content .video-block{
            width: 100vw;
            margin-left: 50%;
            transform: translateX(-50%);
        }
    }
    @media( max-width: 995px){
        div#content .video-block{
            width: 100%;
        }
    }

    @media (min-width: $medium) {
        .col-layout {
            > div {
                margin-top: $gap2;
            }
            h3 {
                text-align: left;
            }
            .w100  {
                h3 {
                    text-align: center;
                }
            }
        }
    }
}
