#new-logbook-loans{
    #content{
        .content-body{
            column-count: 2;
            h2, p{
                column-span: all;
                text-align: center;
            }
            .themed-h2:first-of-type{
                margin: 36px 0;
            }
            ul{
                height: 100%;
                display: inline-block;
                margin: 0 32px 32px 24px;
                list-style: none;
                li{
                    position: relative;
                    &:before{
                        content: "";
                        display: block;
                        position: absolute;
                        height: 6px;
                        width: 6px;
                        background-color: $green;
                        border-radius: 50%;
                        left: -24px;
                        top: calc(50% - 3px);
                    }
                }
                &:last-of-type{
                    column-span: all;
                    display: block;
                    text-align: center;
                    list-style: none;
                    font-weight: bold;
                    a{
                        color: $green;
                        &:hover{
                            color: $blue2;
                        }
                    }
                    li{
                        &:before{
                            display: none;
                        }
                    }
                }
            }
            @media(max-width: $large){
                column-count: 1;
            }
        }
        .Q-and-A-container{
            article + article{
                margin-top: 32px;
            }
            @media(max-width: $large){
                article{
                    margin-top: 32px;
                    .title{
                        padding: 1em 2em;
                        &::after {
                            transform: translateY(-30%) rotate(-45deg);
                            height: 12px;
                            width: 12px;
                            border-right: 2px solid #FFFFFF;
                            border-top: 2px solid #FFFFFF;
                            transform-origin: center;
                            content: '';
                            right: 20px;
                            top: 50%;
                            position: absolute;
                            transition: transform 0.2s ease-in-out;
                        }
                    }
                    &.closed{
                        .title{
                            &::after{
                                transform: translateY(-70%) rotate(135deg);
                            }
                        }
                    }
                    &:first-of-type{
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
