@mixin breakpoint($args...) {

	$defaults : (
    breakpoint : null,
    rule : 'min',
  );

  $settings : get-settings($defaults, $args...);

  $rule : map-get($settings, rule);
  $breakpoint : map-get($settings, breakpoint);

  $breakpoint : get-breakpoint($breakpoint);

	@if $rule == 'min' or $rule == '>' {
		@media (min-width:$breakpoint + 1) {
	    @content;
	  }
	}	@elseif $rule == 'max' or $rule == '<' {
		@media (max-width:$breakpoint) {
	    @content;
	  }
	}
}
