// Responsive layout
#completed-loans-grid {
  padding: $gap4 0;

  // Section position padding override
  representative-example + & {
    padding-top: 0;
  }

  h3 {
    margin-bottom: 32px;
    width: 100%;
    text-align: center;

    > * {
      white-space: nowrap;
    }
  }
  .completed-loan-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease;

    &.slick-initialized {
      visibility: visible;
      opacity: 1;
    }

    .completed-loan-card {
      width: 100%;
      margin-top: $gap2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:first-child {
        margin: 0;
      }
    }

    @media (min-width: $small) {
      // Revert slick slider styles
      opacity: 1;
      visibility: visible;

      .completed-loan-card {
        margin-top: $gap;
        width: calc(50% - #{$gap0});

        &:nth-child(-n + 2) {
          margin-top: 0;
        }
      }
    }
    @media (min-width: $small-medium) {
      .completed-loan-card {
        margin-top: $gap2;
        width: calc(50% - #{$gap});
      }
    }
    @media (min-width: $medium) {
      .completed-loan-card {
        width: calc(25% - #{$gap});
        margin-top: 0;
      }
    }
    @media (min-width: $large) {
      .completed-loan-card {
        width: calc(25% - #{$gap2});
      }
    }
    @media (max-width: $small-medium) {
      .slick-arrow {
        display: none;
      }
    }
  }
  .cta-container {
    margin-top: $gap2;
    width: 100%;
    text-align: center;
  }
}

// Card styles
.completed-loan-card {
  text-transform: uppercase;
  text-align: center;
  background-color: $grey;
  user-select: none;

  header {
    background-color: $green;
    color: $white;
    padding: $gap0 $gap;
    font-size: 12px;
    letter-spacing: 1px;
  }

  .detail-wrap {
    padding: $gap2 $gap;

    h2 {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.2;
      margin: 0;
      min-height: 2.4em;
      vertical-align: middle;
    }

    img {
      max-width: 100%;
      margin-top: $gap;
      object-fit: cover;
    }

    ul {
      margin-top: $gap;

      li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & + li {
          margin-top: $gap;
        }
      }
    }
  }

  footer {
    background-color: $blue1;
    color: $white;
    font-size: 12px;
    letter-spacing: 1px;

    a {
      display: block;
      padding: $gap;
      color: inherit;
    }
    svg {
      fill: $green;
      height: 1em;
      width: auto;
      vertical-align: middle;
      margin-right: 0.5em;
    }
  }

  // Responsive
  @media (min-width: $large) {
    header {
      padding: $gap $gap2;
    }
    .detail-wrap {
      padding: $gap2;
    }
    footer {
      a {
        padding: $gap $gap2;
      }
    }
  }
}
