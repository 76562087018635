#about-us {
    padding: $gap1;

    > div {
        display: flex;
        flex-direction: column;
        gap: $gap2;

        figure {
            margin: 0 auto;
            min-width: calc(50% - #{$gap});

            img {
                max-width: 100%;
            }
        }
    }

    @media (min-width: $medium) {
        padding: $gap4 $gap6;
    }
    @media (min-width: $max) {
        > div {
            flex-direction: row;
        }
    }
}

// Page specific styles
body#help {
    #about-us {
        padding: 0;

        > div {
            flex-direction: column;
        }
    }
}
