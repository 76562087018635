#research-statement {
    @extend .content-block;
    background-color: $grey;
    padding: $gap1;
    svg {
        width: 25%;
        height: auto;
        fill: $blue2;
        position: relative;
    }

    // Overrides
    & > .col-layout > .w100  {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        h4, p {
            text-align: left;
        }
        div{
            width: 47.5%;
        }
        h4{
            line-height: 1.2;
            margin-bottom: 1em;
        }
    }
    @media (max-width: 800px) {
        .col-layout > .w100  {
            display: block;
            svg{
                height: auto;
                width: 100%;
            }
            div{
                width: 100%;
                margin: 32px 0;
            }
            h4, p{
                text-align: center;
            }
        }

    }

    @media (min-width: $medium) {
        padding: $gap4 $gap6;

    }
}
