$primary: #96c11f; $secondary: #29235c; $fonts: '/assets/fonts/'; $images: '/assets/images/'; $sprites: '/assets/images/sprites/';@import './src/vendor/ds/imports', 'settings';////////////////////////////////////////////////////////////////////////////////
// Global Styling
////////////////////////////////////////////////////////////////////////////////
// NOTE: This is the only way it works...
$slick-font-path: "../fonts/";
$slick-loader-path: "../images/";
@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/slick-carousel/slick/slick-theme.scss";

body {
  site-wrapper {
    display:block;
    // flex-direction:column;
    position: relative;
    min-height: 100vh;
    min-width:$min;
    width:100%;
    margin: 0;
  }

  &.locked {
    // height: 100vh;
        site-wrapper {
            touch-action: none;
            // padding-right:var(scrollbar-width, 0);
            &:before { opacity:1; }
        }
    @include devices(mobile tablet) {
      site-wrapper { height:inherit; }
    }
  }

  @include outside-break($min) {
      overflow-x:hidden;
  }

  &.dev-environment {
      @include show-breakpoints();
  }

  // Responsive
  @media (min-width: $medium + 1) {
    margin: $sticky-height auto 0;
  }
}

h1 { font-weight: 700; margin-bottom:$gap; color:$text-colour; line-height:1; }
h2, h3, h4, h5, h6 { margin-bottom:$gap0; color:$text-colour; line-height:1; text-transform: uppercase; }

h1, %h1 { @include responsive-fontsize($h1, $h2); font-weight: 600;  }
h2, %h2 { @include responsive-fontsize($h2, $h3); font-weight: 600; line-height: 32px;}
h3, %h3 { @include responsive-fontsize($h3, $h4); font-weight: 600; }
h4, %h4 { @include responsive-fontsize($h4, $h5); font-weight: 600; text-transform: capitalize; }
h5, %h5 { @include responsive-fontsize($h5, $h6); }
h6, %h6 { @include responsive-fontsize($h6, $font-size); }

h3 { margin-bottom:$gap2; }

q, blockquote {
  quotes: "“" "”" "‘" "’";
  &:before { content: open-quote; }
  &:after { content: close-quote; }
}

a { @include transition(color); }

pre {
  background-color:white;
  color:black;
  text-transform:none;
  text-decoration:none;
  text-align:left;
  display:block;
  padding:$gap;
  position:relative;
  z-index:999;
}

// =============================================================================
// Buttons
// =============================================================================

.button {

    --button-theme:#{var(primary)};

    &.blue    { --button-theme : #{secondary}; }

    position: relative;
    display:inline-flex;
    align-items:center;
    justify-content:center;
    padding:0.5714em 0.8095em;
    text-transform: uppercase;
    font-size:21px;
    line-height:1;
    border:1px solid transparent;
    font-weight: 600;
    @include border-radius(5);

    // Standard Button Colours
    @include button(
        $transitions : color background-color border-color,
        $initial:(
            'background' : var(button-theme),
            'colour'     : white,
            'stroke'     : white,
            'fill'       : white,
            'border'     : transparent,
        ),
        $hover:(
            'background' : darken($green, 10%),
            'colour'     : white,
            'stroke'     : white,
            'fill'       : white,
            'border'     : var(button-theme),
            // 'important'  : true
        ),
    );

  &.blue {
        @include button(
            $initial:(
                'background' : var(secondary),
                'colour'     : $white,
                'stroke'     : transparent,
                'fill'       : transparent,
                'border'     : transparent
            ),
            $hover:(
                'background' : darken($blue1, 10%),
                'colour'     : white,
                'stroke'     : white,
                'fill'       : white,
                'border'     : transparent,
                'important'  : true
            ),
        );
    }

  &.lightblue {
        @include button(
            $initial:(
                'background' : $blue2,
                'colour'     : $white,
                'stroke'     : transparent,
                'fill'       : transparent,
                'border'     : transparent
            ),
            $hover:(
                'background' : darken($blue2, 10%),
                'colour'     : white,
                'stroke'     : white,
                'fill'       : white,
                'border'     : transparent,
                'important'  : true
            ),
        );
    }

  &.clear {
        @include button(
            $initial:(
                'background' : transparent,
                'colour'     : $blue1,
                'stroke'     : transparent,
                'fill'       : transparent,
                'border'     : $blue1
            ),
            $hover:(
                'background' : $blue2,
                'colour'     : white,
                'stroke'     : white,
                'fill'       : white,
                'border'     : transparent,
                'important'  : true
            ),
        );
    }

  &.small{
      padding:10px;
    font-size: 15px;
  }

    svg {
        margin-left:$gap0;
        &[class*="arrow-"]:not([class*="arrow-left"]) {	margin-left:$gap0 + 3; }
        &[class*="arrow-left"] { margin-left:0; margin-right:$gap0 + 3;	}
        @include transition(stroke fill);
    }
    &:hover svg[class*="arrow-"] {
        stroke:white;
    }
}

.buttons { display:flex; justify-content:flex-end; align-items:center;
  a:first-of-type{ margin-right:10px;}
}

// =============================================================================
// Error Messages
// =============================================================================

error-messages{
  display: block;
  width:100%;
  height: auto;
  border:2px solid red;
  border-radius: 6px;
  margin-bottom: $gap2;
  padding:$gap;
  z-index: 1;
  p{
    color: red;
    margin: 0 !important;
  }
}

input-fields{
  div.error {
    input{
      border: 1px solid red;
      color:red;
    }
  }
}
ul.errors {
  list-style: none;
  color: red;
  text-align: left;
  margin-top: 2%;

  li {
    margin-top: 0;
    font-size:0.75em;
  }
}

// =============================================================================
// Error pages
// =============================================================================

body[id^="error-"] {
    main {
        text-align: center;

        h1, p {
            margin-bottom: 1em;
        }
        p {
          font-size: 15px;
        }
    }
}

// =============================================================================
// Content and Aside
// =============================================================================

content{
  background:$grey;
  display:block;
}

content-aside {
  display: flex;
  flex-direction: column;
  background:$grey;

  #content{
    flex-grow:5;
    p { line-height: 1.4; }
    p { margin-bottom: $gaps+px; }
    h2 { font-size: 22px; color: $blue2; margin:$gaps*2+px 0 $gaps+px; }
    h3 { color:$blue2; margin-bottom: $gaps+px;  }
    ul {
      margin:$gaps*2+px;
      list-style: disc;
      li { margin:$gaps+px 0; }
    }
    ol {
      margin:$gaps*2+px;
      list-style: decimal;
      li { margin:$gaps+px 0; }

      // Mimic Redactor nested list styles
      ol li {
        list-style-type: lower-alpha;
      }
      ol ol li {
        list-style-type: lower-roman;
      }
    }
    img{
        max-width: 100%;
    }
  }

  hr { opacity: 0.3; width: 80%; margin: 50px auto; }

  aside{
    //flex-grow: 1;
    width:100%;
    background:#ededed;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    contact-details {
      border-top: 1px solid $grey2;
      margin-bottom:0;
      padding-bottom:0;
       p span { display:none; }
     }
  }

  @media(min-width: $medium){
    flex-direction: row;

    aside{
      width:375px;
      min-width: 375px;
    }
  }
}

.quote-aside{

  padding:30px 20px;
  text-align: center;
  position: sticky;
  top: 52px;
  application-form{
      padding: 30px 0;
  }
  h2{
      padding: 0 20px;
      font-size: 28px;
  }

  input-fields{
    display: block;
    > div { max-width: 100%; margin-bottom: 12px;}
    .checkboxes{
        margin-bottom: 24px;
        .inputs{
            margin-top: 24px;
        }
    }
  }
  .form-notice{
      margin: 24px 0;
      text-align: center;
  }
  h4{
    text-transform: uppercase;
    font-size: 23px;
    line-height: 27px;
    margin: 0;
    letter-spacing: $letterspace;
  }

  .submits {
      flex-direction: column;
      align-items: center;

      >input
      // DEPRECATED - 2023-07-07
      // .applyByPhone 
      {
          max-width: 100%;
      }
  }

  input[type="submit"],
  button[type="submit"] {
    margin-top: 10px;
  }
}

// Page Structure

content-aside {

  #content {
      padding:$gaps*2+px;
      .featuredImage{
        margin: 12px 0 40px 0;
      }
  }

  aside{
    h3{
      display: block;
      background: $blue2;
      padding: 30px;
      color: $white !important;
      margin-bottom: 0;
    }
    > ul{
      background: $white;
      margin: 0;
      li{
        display: block;
        position: relative;
        width:100%;
        padding-left: 60px;
        border-bottom:1px solid #e3e1e1;

        a {
          height: 60px;
          display: table-cell;
          vertical-align: middle;;
        }

        &:before {
          position:absolute;
          left: 25px;
          top:50%;
          transform: translateY(-50%);
          background: $green;
          padding-left: 3px;
          width:20px;
          height:20px;
          border-radius:50%;
          content: "\203A";
          font-size: 30px;
          line-height: 15px;
          text-align:center;
          color: $white;
        }
      }
    }
  }
}
.loyc-logo{
    display: block;
    margin: 32px 32px 32px 0;
    display: block;
    content:url("/assets/images/loanonyourcar-logo-wide.svg");
    width: 550px;
    z-index: 5;
}
@include break($medium, '<'){
    .loyc-logo{
        content:url("/assets/images/loanonyourcar-logo-stacked.svg");
        width: 250px;
        margin: 32px 0;
    }
    header{
        .generic{
            a{
                &:last-of-type{
                    display: block;
                }
            }
        }
    }
}

@keyframes notificationSlider {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

// GLOBAL NOTIFICATION BAR
.notification-bar {
  color: white;
  background-color: #00A7E7;
  font-size: 14px;
  position: relative;
  // (font-size * line-height) + (2 * padding)
  // min-height: calc((2em * 1.14) + (2 * 8px));

  @include break($small, '>') {
    font-size: 16px;
  }
  @include break($medium, '>') {
    font-size: 18px;
  }

  & ~ main {
    padding-top: 0;
  }
  &.animated {
    .notification {
      opacity: 0;
      animation: notificationSlider 8s infinite;

      // NOTE: Hard-coded for number of items
      @for $i from 1 through 2 {
        &:nth-child(#{$i}n) {
          animation-delay: #{($i * 4) - 4}s;
        }
      }
    }
  }

  .notification {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-width: 100%;
  }
  .notification-content{
      margin: 0 auto;
      position: relative;
  }

  svg {
    height: 2em;
    width: 3.33333em;
    margin-right: 0.5em;
    position: absolute;
    left: -4em;
    top: 0.7em;
  }

  .sell-your-car{
      width: 35%;
      background-color: $green;
      border-right: 0px solid white;
      max-width: 300px;
      position: relative;
      padding: 16px 12px 16px 58px;
      min-height: calc(100% + 16px);
      transition: all 0.3s ease-in-out;
      border: 3px solid $green;
      a{
          width: 100%;
          color: white;
          @include break($small, '>') {
            font-size: 16px;
          }
          @include break($medium, '>') {
            font-size: 21px;
          }
          &:before{
              content: "NEW";
              font-size: 8px;
              display: block;
              z-index: 1001;
              top: 24px;
              left: 29px;
              transform: rotate(-30deg);
              transition: all .1s ease-in-out;
              color: white;
          }
          &:after{
              content: "";
              display: block;
              position: absolute;
              height: 30px;
              width: 30px;
              border-radius: 50%;
              background-color: $blue1;
              top: 14px;
              left: 25px;
              z-index: 1000;
          }
          &:before,
          &:after{
              position: absolute;
          }

      }
      &:hover{
          background-color: darken($green, 10);
          border: 3px solid darken($green, 10);

      }
  }
  @include break($medium, '<') {
      svg{
          display: none;
      }
      .notification-content{
          text-align: left;
          padding-left: 10px;
          text-transform: capitalize;
      }
      .sell-your-car{
          padding: 16px 12px 16px 40px;
          a:before{
              left: 10px;
              top: 21px;
          }
          a:after{
              left: 6px;
              top: 11px;
          }
      }
  }
  @include break($small-medium, '<') {
      .sell-your-car{
          a:before{
              left: 10px;
              top: 28px;
          }
          a:after{
              left: 6px;
              top: 18px;
          }
      }
  }
  @include break($small, '<') {
      .sell-your-car{
          a:before{
              left: 10px;
              top: 34px;
          }
          a:after{
              left: 6px;
              top: 24px;
          }
      }
  }
}

// CHRISTMAS
.xmas{
    position: absolute;
    top: 0;
    width: 28%;
    z-index: 0;
    &.left{
        left: 0;
    }
    &.right{
        right: 0;
    }
}

// HELPERS
.text-nowrap {
  white-space: nowrap;
}

//SCROLL lock
 @include break(767px, '<'){
    .scroll-lock{
        overflow: hidden;
    }
}


// SCROLL BEHAVIOUR
html {
    // Sticky navigation height
    scroll-padding-top: 55px;
    scroll-behavior: smooth;
}


// HOMEPAGE CONTENT BLOCKS
.content-block {
    // Shared Styles
    h1,
    h2,
    h3 {
        user-select: none;
        letter-spacing: initial;
        text-transform: uppercase;
        line-height: 1.4;
    }

    h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 1em;
    }

    p {
        line-height: 1.4;
        margin-bottom: 1em;

        &:last-child {
            margin-bottom: 0;
        }

        a:not(.button) {
            color: $green;

            &:active,
            &:focus,
            &:hover {
                color: darken($green, 10%);
            }
        }
    }

    header {
        text-align: center;
    }

    table {
        border-spacing: 4px;
        border-collapse: separate;
        max-width: 100%;
        line-height: 1.4;
        max-width: 100%;

        td,
        th {
            border-radius: 4px;
            padding: $gap;
        }

        tbody {
            p {
                text-align: left;
            }
        }
    }

    // Layout
    .col-layout {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        >div {
            width: 100%;
        }

        .w100 {
            overflow-x: auto;
        }
    }

    @media (min-width: $medium) {
        .col-layout {

            .w50g,
            >div {
                width: 48%;
            }

            .w100 {
                width: 100%;
            }
        }
    }
}


// Social icons
.social-icon {
  display: inline-block;
  fill: $blue2;

  & + .social-icon {
    margin-left: 1em;
  }

  svg {
    width: 25px;
    height: 25px;
    transition: fill 300ms;
  }

  &:active,
  &:focus,
  &:hover {
    svg {
      fill: $white;
    }
  }
}


// CTA "Apply Now" Notice
.cta-notice,
body#pawn-your-car #withdraw-equity-prestige .col-layout > div:nth-child(odd) p:first-of-type.cta-notice {
    user-select: none;
    color: $tulip-tree;
    font-size: 24px;
    font-style: italic;
    text-transform: uppercase;
    font-weight: 400;
    margin: $gap 0;
}
body#pawn-your-car #type-of-cars-pawned .cta-notice {
    margin-top: $gap2;

    & + .button {
        margin-top: 0;
    }
}
trust-pilot-reviews .review-page-cta .cta-notice {
    margin-top: $gap4;

    & + p:last-of-type {
        margin-top: 0;
    }
}
@import 'src/sass/components/about-us', 'src/sass/components/accordion', 'src/sass/components/car-brands', 'src/sass/components/completed-loans-grid', 'src/sass/components/contact-details', 'src/sass/components/cta-panes', 'src/sass/components/forms', 'src/sass/components/generic-section', 'src/sass/components/how-it-works', 'src/sass/components/loan-features', 'src/sass/components/loyc-vs-competitors', 'src/sass/components/modal', 'src/sass/components/promos', 'src/sass/components/recent-news-grid', 'src/sass/components/representative-example', 'src/sass/components/research-statement', 'src/sass/components/sell-form', 'src/sass/components/sticky-navigation', 'src/sass/components/switch-and-save', 'src/sass/components/tabs', 'src/sass/components/trustpilot', 'src/sass/components/unique-selling-points', 'src/sass/components/usp-banner', 'src/sass/components/ver-comparison', 'src/sass/components/ver-guide', 'src/sass/components/ver-in-emergency', 'src/sass/components/video-flyout', 'src/sass/pages/bad-credit', 'src/sass/pages/complete', 'src/sass/pages/holding', 'src/sass/pages/how-it-works', 'src/sass/pages/index', 'src/sass/pages/introducer', 'src/sass/pages/landing-pages', 'src/sass/pages/new-logbook-loans', 'src/sass/pages/news', 'src/sass/pages/nhs-discount', 'src/sass/pages/pawn-your-car', 'src/sass/pages/premium', 'src/sass/pages/reviews', 'src/sass/pages/switch-and-save', 'src/sass/pages/vehicle-equity-release', 'src/sass/pages/we-buy-your-car', 'src/sass/sections/footer', 'src/sass/sections/header', 'src/sass/sections/locations', 'src/sass/sections/main', 'src/sass/sections/navigation', 'src/sass/sections/sidebar';