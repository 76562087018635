#player {
    display: none;

    &.active {
        display: block;
        position: fixed;
        top: 15vh !important;
        left: 15vw;
        height: 70vh;
        width: 70vw;
        z-index: 99;

        &::before {
            content: "";
        }
    }
}

#video-flyout {
    background-color: #29235c;
    padding: 6px 32px 12px 12px;
    position: fixed;
    z-index: 7;
    display: none;
    &.ready {
        display: flex;
        flex-direction: column;
    }

    #minimise-btn {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: 16px;
        height: 100%;

        &:before {
            content: "";
            color: white;
            position: absolute;
            top: 15px;
            right: 15px;
            padding: 5px;
            border-right: 2px solid white;
            border-bottom: 2px solid white;
            transform: rotate(-45deg);
        }
    }
    .video{
        opacity: 0;
        transition: opacity .5s ease-in;
        flex-direction: column;
        justify-content: space-around;
        &::after,
        &::before {
            display: block;
            content: "";
            position: absolute;
            pointer-events: none;
            top: 35%;
            left: 50%;
            transform: translate(-50% -50%);
            z-index: 3;
        }

        &::after {
            width: 0;
            height: 0;
            border: 16px solid transparent;
            border-left: 20px solid #fff;
            margin-top: -19px;
            margin-left: -10px;
            -webkit-filter: drop-shadow(0px 0px 2px rgba(0,0,0,0.25));
        }

        &::before {
            margin-top: -34px;
            margin-left: -34px;
            width: 60px;
            height: 60px;
            border: 3px solid #fff;
            border-radius: 50%;
            box-shadow: 0 0 4px rgba(black, 0.18);
            background: rgba(black, 0.42);
        }
        &.transition-in{
            animation-name: fade-in;
        	animation-fill-mode: both;
        	animation-duration: .5s;
        }
        &.transition-out{
            animation-name: fade-out;
            animation-fill-mode: both;
            animation-duration: .5s;
        }
        opacity: 1;
    }

    .video:hover {
        cursor: pointer;

        &::before {
            background: rgba(black, 0.7);
        }
    }
    lite-youtube {
        position: relative;
        width: 100%;
        background-position: center center;
        background-size: cover;
        cursor: pointer;
        button{
            display: none;
        }
        @media (min-width: 840px) {
            max-height: 110px;
            height: 100%;
            width: 195px;
        }
    }
    p{
        color: white;
        margin: 0 auto;
        max-width: 80%;
    }
    .next-prev-btns{
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 24px;
        width: 95%;
        padding: 0 16px;
        left: -4px;
        button{
            position: relative;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background-color: $grey;
            border: 1px solid $grey1;
            &:first-of-type, &:last-of-type{
                &:before{
                    content: "";
                    height: 10px;
                    width: 10px;
                    border-color: grey;
                    position: absolute;
                    border-style: solid;
                    transform: rotate(-45deg);
                    top:6px;
                }
            }
            &:first-of-type{
                &:before{
                    border-bottom: 2px;
                    border-right: 2px;
                    left: 8px;
                }
            }
            &:last-of-type{
                &:before{
                    border-top: 2px;
                    border-left: 2px;
                    left: 5px;
                }
            }
        }
    }
    .tab-track{
        justify-content: center;
        display: flex;
        margin: 6px 0 0 0;
        .step {
          height: 10px;
          width: 10px;
          margin: 0 2px;
          background-color: $grey1;
          border: none;
          border-radius: 50%;
          display: inline-block;
          opacity: 0.5;
          &.active {
              background-color: $green;
              opacity: 1;
          }
        }
    }
}

#video-overlay {
    opacity: 0;

    &.active {
        position: absolute;
        top: 0;
        left: -100%;
        width: 300%;
        height: 100vh;
        background-color: #29235c;
        opacity: 0.7;
        transition: opacity 0.3s ease-in;
        z-index: 98;
    }
}
@media(min-width: 841px) {
    #video-flyout {
        top: 65vh;
        transform: translateX(0%);
        right: 0;
        transition: transform 0.3s ease-out;
        #minimise-btn {
            display: block;
        }
        .video{
            min-height: 160px;
            max-width: 195px;
        }
        &.minimised, &.man-mini {
            transform: translateX(calc(100% - 28px));
            padding: 6px 12px 12px 32px;

            img {
                right: 0;
            }

            #minimise-btn {
                left: 1px;
                right: auto;
                padding: 16px;

                &:before {
                    top: 15px;
                    left: 10px;
                    right: auto;
                    position: absolute;
                    transform: rotate(-225deg);
                }
            }
            .next-prev-btns{
                display: none;
            }
        }
    }
    .next-prev-btns, .tab-track{
        display: none;
    }
}
@media(max-width: 840px) {
    #video-flyout {
        position: relative;
        width: 100%;
        min-height: 265px;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 32px;
        left: 0;
        right: 0;
        padding: 0;
        background-color: $grey;
        img {
            width: 100%;
            height: auto;
        }
        p{
            display: block;
            text-align: center;
            padding: 16px 16px 0 16px;
            margin-bottom: 0px;
            color: $blue1;
        }

        .video{
            &::after,
            &::before {
                top: 44%;
                left: 52%;
            }
        }
        .next-prev-btns{
            bottom: 30px;
            width: 100%;
            padding: 0 16px;
            left: 0;
            button{
                height: 30px;
                width: 30px;
                &:first-of-type, &:last-of-type{
                    &:before{
                        height: 12px;
                        width: 12px;
                        top: 8px;
                    }
                }
                &:first-of-type{
                    &:before{
                        border-bottom: 3px;
                        border-right: 3px;
                        left: 10px;
                    }
                }
                &:last-of-type{
                    &:before{
                        border-top: 3px;
                        border-left: 3px;
                        left: 6px;
                    }
                }
            }
        }
        .tab-track{
            margin: 12px 0;
            .step {
              height: 15px;
              width: 15px;
              margin: 0 2px;
            }
        }
    }

    #player.active {
        width: 90%;
        left: 5%;
    }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
