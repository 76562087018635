#vehicle-equity-release-guide {
    $section-background-color: $grey;
    $arrow-size: 30px;
    $svg-height: 60px;

    @extend .content-block;
    background-color: $section-background-color;

    header {
        position: relative;
        background: linear-gradient(rgba(#0b2760, 0.65), rgba(#0b2760, 0.65)), url('https://loyc.imgix.net/images/bg-vehicle-equity-release.jpg') center center no-repeat;
        background-size: cover;
        padding: $gap;
        padding-bottom: $gap2 + $arrow-size;

        &:before,
        &:after {
            position: absolute;
            bottom:0;
            width: 50%;
            display: block;
            content: '';
            border-bottom: $arrow-size solid $section-background-color;
        }
        &:before {
            left: 0;
            right: 50%;
            border-right: $arrow-size solid transparent;
        }
        &:after {
            left: 50%;
            right: 0;
            border-left: $arrow-size solid transparent;
        }
        h2 {
            font-size: 24px;
            font-weight: 100;
            color: $white;
            margin: 0;

            b, strong {
                color: $green;
            }
        }
    }

    // Overrides
    .col-layout {
        padding: 0 $gap $gap;

        > div {
            margin-top: $gap;
        }
        .w100 {
            text-align: center;

            p {
                text-align: inherit;

                &:first-of-type {
                    color: $blue2;
                    text-transform: uppercase;
                    font-size: 1.2em;
                }
            }
        }

        h3 {
            position: relative;
            margin-top: $svg-height + $gap;
            margin-bottom: 1.2em;
            text-align: center;

            svg {
                position: absolute;
                left: 50%;
                top: -($svg-height + $gap);
                transform: translateX(-50%);
                width: $svg-height;
                height: $svg-height;
                fill: $blue2;
            }
        }
    }

    @media (min-width: $medium) {
        header {
            padding: $gap9 $gap4;

            h2 {
                font-size: 30px;
            }
        }

        .col-layout {
            padding: 0 $gap4 $gap4;

            > div {
                margin-top: $gap4;
                &:first-of-type {
                    margin-top: calc(#{$gap4} - #{$arrow-size});
                }
            }
            h3 {
                text-align: left;
                margin-top: 0;
                vertical-align: middle;
                position: relative;
                top: auto;
                left: auto;

                svg {
                    position: relative;
                    left: auto;
                    top: auto;
                    transform: none;
                    margin-right: 0.5em;
                    vertical-align: middle;
                }
            }
        }
    }
}
