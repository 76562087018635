/// 10px on each corner. Call also use the string "all"
/// @include border-radius(10)
/// @include border-radius( 10)

@mixin border-radius($args...) {

	$default : var(border-radius);

	$top-left     : null;
	$top-right    : null;
	$bottom-right : null;
	$bottom-left  : null;
	$important    : null;
	$all : null;
	$direction : null;

	@each $value in $args {
		@if is-important($value) {
			$important : !important;
		}
		@elseif is-number($value) {
			@if is-null($top-left) { $top-left : add-unit($value); }
			@elseif is-null($top-right) { $top-right : add-unit($value); }
			@elseif is-null($bottom-right) { $bottom-right : add-unit($value); }
			@elseif is-null($bottom-left) { $bottom-left : add-unit($value); }
		}
		@elseif is-var($value) {
			@if is-null($top-left) { $top-left : var($value); }
			@elseif is-null($top-right) { $top-right : var($value); }
			@elseif is-null($bottom-right) { $bottom-right : var($value); }
			@elseif is-null($bottom-left) { $bottom-left : var($value); }
		}
		@elseif is-string($value) {
			@if $value == circle { $all : 50%; }
			@elseif contains(top right bottom left, $value) {
				$direction : $value;
			}
		}
	}

	@if is-null($all) and is-null($top-right) and is-null($bottom-right) and is-null($bottom-left) {
		$all : if(is-null($top-left), $default, $top-left);
	}

	@if not is-null($all) and is-null($direction) {
		border-radius:$all $important;
	} @else {

		@if not is-null($direction) {
			$value1 : $top-left or $default;
			$value2 : $top-right or $top-left or $default;
			@if $direction == top or $direction == bottom {
				border-#{$direction}-left-radius : $value1 $important;
				border-#{$direction}-right-radius : $value2 $important;
			} @elseif $direction == right or $direction == left {
				border-top-#{$direction}-radius : $value1 $important;
				border-bottom-#{$direction}-radius : $value2 $important;
			}
		}
		@else {
			border-radius:$top-left $top-right $bottom-right $bottom-left $important;
		}

	}

}
