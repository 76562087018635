body#complete {
  main {
    padding-top: 0;
  }

  #content {
    display: flex;
    flex-direction: column;
    padding: $gap5 $gap2;
    text-align: center;

    h2 {
      font-size: 48px;
      font-weight: 600;
      margin-bottom: 32px;
    }
    h4 {
      font-size: 36px;
      margin-bottom: 40px;
      letter-spacing: 3px;
      word-wrap: break-word;
    }

    h5 {
      font-size: 20px;
      color: $blue2;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: 600;
    }

    p {
      font-size: 20px;
      line-height: 1.4;
      display: block;
      margin: 0px auto;
    }
    b,
    a {
      color: $green;
    }
    .button {
      color: white;
      margin-top: 32px;
    }
    a {
      font-size: 1.4em;
    }
  }
}
