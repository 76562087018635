////////////////////////////////////////////////////////////////////////////////
// Placeholders                                                    #placeholders
////////////////////////////////////////////////////////////////////////////////

// =============================================================================
// Position
// =============================================================================

%hidden {
  opacity:0;
  pointer-events:none;
}

%visible {
  opacity:1;
  pointer-events:auto;
}

%full {
  position:absolute;
  top:0; left:0; right:0; bottom:0;
  width:100%; height:100%;
}

%absolute-center {
  position:absolute;
  top:0; left:0; right:0; bottom:0;
  margin:auto;
}

// =============================================================================
// Clear
// =============================================================================

%clear-fix, %clearfix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

// =============================================================================
// Pseudo
// =============================================================================

%sudo, %pseudo {
  content:"";
  @extend %full;
}

// =============================================================================
// Text
// =============================================================================

%font-smooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%font-default {
  font-family: Helvetica, Arial, sans-serif;
  @extend %font-smooth;
}

%hide-text {
  text-indent:110%; overflow:hidden; white-space:nowrap;
}

%show-text {
  text-indent:0; overflow:visible; white-space:normal; user-select:all;
}
