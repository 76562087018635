body.landing-pages {

    // Themes
    &.themed-pawn-your-car {
        --secondary: #{$white};

        *::selection {
            background-color: $tulip-tree;
        }

        color: $white;

        site-wrapper {
            background-color: $thunder;
        }

        // Sticky Navigation
        .sticky-navigation {
            background-color: $mine-shaft;

            p {
                color: white;

                &:before {
                    background-color: $tulip-tree;
                }
            }

            >ul li {
                border-right-color: $black;

                &:hover {
                    background-color: $tulip-tree;
                }

                &:last-of-type {
                    background-color: $tulip-tree;

                    a {
                        color: $thunder;

                        &:before {
                            color: $white;
                        }

                        &:after {
                            background-color: $mine-shaft;
                        }
                    }
                }
            }

            .menu-trigger {
                background-color: $black;

                svg {
                    fill: $tulip-tree;
                }
            }
        }

        // Navigation Bar
        navigation-bar {
            background-color: $tulip-tree;

            &.open>a {
                background-color: $tulip-tree;
            }

            nav {
                background-color: inherit;
            }

            ul li a {
                color: $thunder;

                &:hover {
                    background-color: darken($tulip-tree, 10%);
                }
            }
        }

        // Buttons
        .button {
            --button-theme:#{$tulip-tree};
            color: $thunder;

            &:hover,
            &:active,
            &:focus {
                background-color: darken($tulip-tree, 10%);
                color: $thunder;
            }
        }

        #content {
            background-color: transparent;
            align-items: normal;

            >header {
                margin: 0;
                text-align: center;

                .brand-logo {
                    display: block;
                    width: 100%;
                }

                a {
                    display: inline-block;
                }

                h1 {
                    font-size: 26px;
                    font-weight: 400;
                    line-height: 1.2;
                    color: $husk;
                    margin-top: $gap2;
                }

                h2 {
                    position: relative;
                    color: $white;
                    font-size: 16px;
                    width: fit-content;
                    margin: $gap2 auto 0;

                    &:empty {
                        display: none;
                    }
                    &:before,
                    &:after {
                        content: '';
                        display: block;
                        height: 2px;
                        width: 0.5em;
                        background-color: $tulip-tree;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:before {
                        left: -1.5em;
                    }

                    &:after {
                        right: -1.5em;
                    }
                }

                img {
                    width: 100%;
                    max-width: 100%;
                    // Offset form margins
                    margin-bottom: -$gap2;
                }
            }

            form {
                background-color: transparent;
                margin: 0;

                // Forms
                input-fields {
                    .radios {
                        flex-basis: auto;
                        max-width: 100%;
                        width: 100%;
                        color: $husk;

                        .inputs {
                            display: inline-flex;

                            legend {
                                font-size: 1.2em;
                                font-weight: 400;
                            }

                            label {
                                color: $white;
                                margin-left: $gap2;
                                margin-top: $gap;
                            }
                        }

                        .errors {
                            text-align: center;
                            margin-top: $gap0;
                        }
                    }
                    .inputs a {
                        color: $tulip-tree;
                    }

                    .checkboxes {
                        color: $dusty-gray;

                        label:before {
                            background-color: $mine-shaft;
                            border-width: 1px;
                            border-color: $dusty-gray;
                        }

                        label:after {
                            color: $white;
                        }

                        label:hover {
                            color: lighten($dusty-gray, 10%);

                            &:before {
                                border-color: $tulip-tree;
                            }
                        }
                    }

                    input {
                        &.large {
                            background-color: $mine-shaft;
                            color: $white;
                            border-color: transparent;

                            &::-webkit-input-placeholder {
                                color: $white;
                            }

                            &::-moz-placeholder {
                                color: $white;
                            }

                            &:-ms-input-placeholder {
                                color: $white;
                            }

                            &::-ms-input-placeholder {
                                color: $white;
                            }

                            &:-moz-placeholder {
                                color: $white;
                            }

                            &.reg-input {
                                background-color: $husk;
                                color: $black;

                                &::-webkit-input-placeholder {
                                    color: $black;
                                    font-weight: 600;
                                }

                                &::-moz-placeholder {
                                    color: $black;
                                    font-weight: 600;
                                }

                                &:-ms-input-placeholder {
                                    color: $black;
                                    font-weight: 600;
                                }

                                &::-ms-input-placeholder {
                                    color: $black;
                                    font-weight: 600;
                                }

                                &:-moz-placeholder {
                                    color: $black;
                                    font-weight: 600;
                                }
                            }
                        }
                    }

                    @include break($medium, '>') {
                        >div {
                            max-width: calc(50% - #{$gap});
                            flex-basis: 50%;
                            margin-bottom: $gap;
                        }

                        .checkboxes {
                            display: flex;
                            justify-content: space-between;
                            max-width: 100%;

                            >div {
                                margin-left: 0;
                            }

                            .inputs {
                                &+.inputs {
                                    margin-left: $gap;
                                }
                            }
                        }
                    }

                    @include break($medium, '>') {
                        .radios {
                            .inputs {
                                legend {
                                    display: contents;
                                }

                                label {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }

            @include break($medium, '>') {
                margin-top: $gap4;
                // NOTE: imgix compression adds colour banding to these images
                background-image: url('https://loyc.imgix.net/images/bg-masthead-porsche.png?q=100&auto=format');
                background-repeat: no-repeat;
                background-size: auto 90%;
                background-position: right center;

                >header {
                    max-width: 65%;

                    .brand-logo {
                        width: 600px;
                    }

                    h1 {
                        font-size: 38px;
                    }

                    h2 {
                        font-size: 18px;
                    }

                    img {
                        display: none;
                    }
                }

                >form {
                    max-width: 65%;
                }
            }
        }

        // Forms
        error-messages {
            background-color: $thunder;
        }

        // Application Form
        application-form {
            padding: $gap2 0;

            .submits {
                p {
                    color: $white;
                    // Apply again...
                    line-height: 1;
                    font-weight: 700;
                    margin-bottom: 0;
                }

                // DEPRECATED 2023-07-07
                // .applyByPhone {
                //     color: $white;

                //     #callbackTime {
                //         border-color: $white;
                //         color: $tulip-tree;
                //     }
                // }
            }

            @include break($medium, '>') {
                .submits {
                    width: 100%;
                }
            }

            @include break($large, '>') {
                .submits {
                    width: inherit;
                }
            }
        }

        main {
            a {
                color: $tulip-tree;
            }

            header {

                h1,
                h2 {
                    font-size: 26px;
                    font-weight: 400;
                    line-height: 1.2;
                    color: $husk;
                    margin-top: $gap2;
                }

                h3 {
                    position: relative;
                    color: $white;
                    font-size: 16px;
                    width: fit-content;
                    margin: $gap2 auto 0;

                    &:empty {
                        display: none;
                    }

                    &:before,
                    &:after {
                        content: '';
                        display: block;
                        height: 2px;
                        width: 0.5em;
                        background-color: $tulip-tree;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:before {
                        left: -1.5em;
                    }

                    &:after {
                        right: -1.5em;
                    }
                }
            }

            .lp-section {
                ul.tickboxes {
                    li::marker {
                        color: $tulip-tree;
                    }
                }

                &:first-of-type {
                    h3 {
                        color: $white;
                    }
                }

                &:nth-of-type(odd) {
                    background-color: $grey-200;

                    svg {
                        fill: $tulip-tree;
                    }
                }

                &:nth-child(even) {
                    background-color: $mine-shaft;

                    svg {
                        fill: $husk;
                    }
                }
            }
        }

        // Representative Example
        representative-example {
            margin-bottom: $gap;
            background-color: $mine-shaft;

            h5 {
                color: $tulip-tree;
            }
        }

        trust-pilot-reviews {

            h4,
            .review-page-cta {
                color: $white;
            }
        }

        .footer-wrapping {
            fca-footer {
                >img {
                    max-height: 70px;
                }

                p {
                    color: $white;
                }
            }

            footer {
                background-color: $mine-shaft;

                .pyc-logo {
                    width: 100%;
                    max-width: 340px;
                    margin-bottom: $gap;
                }

                .footer-wrap>div:last-of-type ul li:first-of-type {
                    color: $tulip-tree;
                }

                .footer-wrap>div:first-of-type a {
                    color: $tulip-tree;
                }
            }
        }
    }

    main {
        .lp-section {
            padding: $gap2 $gap;

            &:first-of-type {
                h3 {
                    color: $blue2;
                }

                p {
                    &:last-of-type {
                        font-weight: 600;
                    }
                }
            }

            table {
                width: 100%;
                border: 1px solid $dusty-gray;

                td, th {
                    padding: 0.75em;
                    vertical-align: top;
                    border: 1px solid $dusty-gray;
                }
                p:last-of-type {
                    margin-bottom: 0;
                }
            }

            ul {
                list-style: initial;
                margin: 1em 0;
                padding-left: 2.5em;
                display: inline-block;
                text-align: left;

                li {
                    margin-bottom: 0.5em;
                }
            }
            ul.tickboxes {
                li {
                    padding-left: 0.5em;

                    &::marker {
                        content: '✔';
                    }
                }
            }

            // "odd" but not first
            &:nth-of-type(2n+3) {
                background-color: $green3;

                svg {
                    fill: $green;
                }
            }

            &:nth-child(even) {
                background-color: $blue3;

                svg {
                    fill: $blue2;
                }
            }

            &:nth-of-type(n+3) {
                margin-top: $gap;
            }

            header {
                text-align: center;

                svg {
                    width: 80px;
                    height: 80px;
                    margin-bottom: $gap;

                    &+h3 {
                        font-size: 18px;
                    }
                }

                h2,
                h3 {
                    line-height: 1.4;
                    margin-bottom: $gap;
                }

                h2 {
                    @extend %h1;
                    margin-top: 0;
                    font-weight: 100;
                    text-transform: uppercase;
                }

                h3 {
                    @extend %h2;
                    margin-top: 0;
                    text-transform: uppercase;
                }
            }

            .lp-section--content {
                text-align: justify;
            }

            p {
                margin-bottom: 1em;
                line-height: 1.4;

                &:first-of-type {
                    font-weight: 600;
                }

                &:last-of-type {
                    // margin-bottom: 0;
                }
            }
        }

        .cta-button {
            padding: $gap $gap 0;
            text-align: center;
        }

        // Responsive styles
        @media(min-width: $small-medium) {
            .lp-section {
                padding: $gap2;
            }

            .cta-button {
                padding: $gap2 $gap2 0;
            }
        }

        @media(min-width: $medium) {
            .lp-section {
                padding: $gap2;

                &:nth-of-type(n+2) {
                    margin-top: $gap2;
                }
            }

            .cta-button {
                padding: $gap4 $gap4 0;

                a {
                    font-size: 24px;
                }
            }
        }
    }
}

body.themed-custom.landing-pages{

    site-wrapper{

        #application-form{
            background-color: transparent;
            color: $white;
            margin-bottom: 0;
            border-bottom-width: 0;
            border-bottom: 0;
            input-fields input:not(.reg-input){
                background-color: $white;
                color: $secondary;

                &::-webkit-input-placeholder {
                    color: $secondary;
                }

                &::-moz-placeholder {
                    color: $secondary;
                }

                &:-ms-input-placeholder {
                    color: $secondary;
                }

                &::-ms-input-placeholder {
                    color: $secondary;
                }

                &:-moz-placeholder {
                    color: $secondary;
                }

            }
        }

        main {
            #content{
                background-size: cover;
                margin-bottom: 0;
                & + representative-example{
                    background-color: #070526;
                }
                .promo{
                    margin-top: unset;
                    header{

                        h1{
                            font-weight: 800;
                            white-space: wrap;
                            margin: 0 auto $gap;
                            color: $white;
                            text-transform: uppercase;
                            @media(min-width: 1200px){
                                font-size: 60px;
                                max-width: 80%;
                            }
                        }

                        h2{
                            color: white;
                            font-size: 24px;
                            text-transform: unset;
                        }

                        .tp-widget{
                            background-color: white;
                            padding: $gap;
                            width: fit-content;
                            margin: $gap2 auto;
                            > div {
                                z-index: 2;
                            }

                            &:before{
                                width: 75%;
                                content: '';
                                height: 1px;
                                background-color: rgba(255,255,255,0.5);
                                position: absolute;
                                z-index: 1;

                            }
                        }
                    
                    }
                }
            }

            .lp-section{

                .cta-btn{
                    width: 300px;
                    margin: $gap auto;
                }

                &.has-bg-img{
                    background-size: cover;
                }

                p:first-of-type{
                    font-weight: 500;
                }

                h2{
                    font-size: 20px;
                    margin: 0 auto $gap2;
                    color: $blue2;
                    text-align: center;
                    font-weight: 100;
                    @media (min-width: 768px) {
                        max-width: 60%;
                        font-size: 26px;
                    }
                }

                h3{
                    text-align: center;
                    color: $blue1;
                }

                ol{
                    > li {
                        counter-increment: step-counter;
                        padding-left: $gap2;
                        position: relative;
                        margin-bottom: $gap;
                        &::before{
                            content: counter(step-counter);
                            font-size: 16px;
                            color: $primary;
                            position: absolute;
                            top: 0;
                            left: 0;
                            font-weight: 600;
                        }

                    }
                }

                &.cta{
                    text-align: center;
                    color: $white;
                    padding: $gap2;
                    min-height: 500px;
                    justify-content: center;
                    display: flex;
                    flex-direction: column;

                    @media (min-width: 768px) {
                        padding: $gap4;
                    }

                    h2{
                        font-weight: 800;
                        color: var(--spot-colour);
                    }

                    h2, p{
                        margin: $gap auto;
                        @media (min-width: 768px) {
                            max-width: 66%;
                        }
                    }

                    strong{
                        display: block;
                        font-size: 20px;
                        a{
                            color: $white;
                            &:hover{
                                color: var(--spot-colour);
                            }
                        }
                    }
                }

                &.usp{
                    padding: $gap2;
                    background-color: $grey;
                    @media (min-width: 768px) {
                        padding: $gap4;
                    }

                    h2{
                        @media (min-width: 768px) {
                            max-width: 75%;
                        }
                    }

                    h5{
                        margin: 0 auto $gap;
                        font-weight: 800;
                        @media (min-width: 768px) {
                            width: 75%;
                        }
                    }

                    > .cta{
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        margin: 0 auto;
                        @media (min-width: 768px) {
                            width: 50%;
                        }

                        p{
                            &:first-of-type{
                                margin-bottom: 8px;
                            }
                            &:last-of-type{
                                font-weight: 400;
                            }
                            a{
                                color: $primary;
                                &:hover{
                                    color: var(--spot-colour);
                                }
                            }
                        }

                    }
                }

                &.howItWorks{
                    background-color: $grey;
                    text-align: center;
                    > p{
                        text-align: center;
                        width: fit-content;
                        margin: $gap2 auto;
                    }
                }

                &.trustpilot{

                }

                &.solutionBlock{
                    background-color: $grey;
                    padding: $gap2;
                    text-align: center;

                    @media (min-width: 768px) {
                        padding: $gap4;
                    }

                    h2{
                        margin: 0 auto;
                    }

                    svg{
                        max-width: 400px;
                        margin: $gap auto;
                    }

                    p{
                        margin: 0 auto;
                        @media (min-width: 768px) {
                            max-width: 60%;
                        }
                    }
                }

                &.twoColumns{
                    padding: 0;

                    h2{

                        & + h3{
                            font-size: 14px;
                            margin: $gap auto $gap2;
                            font-weight: 800;
                            @media (min-width: 768px) {
                                max-width: 50%;
                            }
                        }
                    }
                    & > .lp-section--two-column{
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-template-rows: 1fr 1fr;
                        grid-gap: $gap2;

                        @media (min-width: 768px) {
                            grid-template-columns: 1fr 1fr;
                            grid-template-rows: 1fr;
                        }

                    }

                    &.block{
                        background-color: transparent;

                        > div {
                            > div {
                                padding: $gap2;
                                background-color: $grey;
                                p:last-of-type{
                                    margin-bottom: 0;
                                }
                                @media (min-width: 768px) {
                                    padding: $gap4;

                                }
                            }
                        }
                    }

                    &.paragraphs{
                        background-color: transparent;
                    }
                }

                &.cta{
                    border: 1px solid purple;
                }
            }
        }
        @include break($medium, '<'){
            header.generic{
                a{
                  &:last-of-type{
                    display:block !important;
                  }
                }
            }
        }
    }

    &#taxi{
        site-wrapper{
            main #content{
                .promo{
                    header{
                        h2{
                            color: var(--spot-colour);
                        }
                    }
                }
            }
        }
    }
}
