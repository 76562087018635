@mixin normalise() {

	/// @author Nicolas Gallagher & Mark Notton
	/// @see github.com/necolas/normalize.css

	// ------------------------------------------------------------------------ //
	// Global
	// ------------------------------------------------------------------------ //

	html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p,
	blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em,
	img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i,
	center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table,
	caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
	embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby,
	section, summary, time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		vertical-align: baseline;
	}

	:root {
	  font-size: 62.5%;
		line-height: 1.15;
		-webkit-text-size-adjust: 100%;
	}

	html {
		box-sizing: border-box;
	}

	*, *:before, *:after {
		&:focus { outline:0; };
		box-sizing: inherit;
	}


	html, body {
		min-width:$min;
		min-height:100%;
	}

	p, pre, blockquote, ol, ul, li, legend, figcaption, body, article, nav, aside, section, header, footer, main, address {
		&:empty { display:none; }
	}

	article, aside, details, figcaption, figure, footer, header, main, menu, nav, section, summary {
		display: block;
	}

	svg, symbol { overflow:visible; }

	details { display: block;	}

	summary { display: list-item;	}

	template { display: none;	}

	[hidden] { display: none; }

	// ------------------------------------------------------------------------ //
	// Section Elements
	// ------------------------------------------------------------------------ //

	@if $normalise-section-elements {

		body {
			font-size: 1.6rem;
			text-rendering: optimizeLegibility;
			-webkit-font-smoothing: antialiased;
			color:$text;
			height:100%;
			width:100%;
		  @extend %font-default, .default !optional;

			@if mixin-exists(selection) {
		  	@include selection;
			}

			@if $show-breakpoints {
				&:not(.dev-mode):after { display:none; }
			}

		}

		address {
			font-style:normal;
		}

	}

	// ------------------------------------------------------------------------ //
	// Header Elements
	// ------------------------------------------------------------------------ //

	@if $normalise-header-elements {

		h1, h2, h3, h4, h5, h6 {
			-webkit-margin-before:0em;
			-webkit-margin-after:0em;
			-webkit-margin-start:0px;
			-webkit-margin-end:0px;
			font-weight: normal;
			@extend .default !optional;
		}

		@if mixin-exists(font-size) {
			h1{ @include font-size($h1, $h2); }
			h2{ @include font-size($h2, $h2*1.2); }
			h3{ @include font-size($h3, $h3*1.2); }
			h4{ @include font-size($h4, $h4*1.2); }
			h5{ @include font-size($h5, $h5*1.2); }
			h6{ @include font-size($h6, $h6*1.2); }
		}

	}

	// ------------------------------------------------------------------------ //
	// Grouping Elements
	// ------------------------------------------------------------------------ //

	@if $normalise-grouping-elements {

		hr {
			border:none;
			height:1px;
			background-color:$text;
			margin:$gap 0;
			box-sizing: content-box;
			overflow: visible;
		}

		blockquote, q {
			quotes: none;
			&:before, &:after {
				content: '';
				content: none;
			}
		}

		ol, ul {
			list-style: none;
		}

		pre {
			font-family: monospace, monospace;
			font-size: 1em;
			background-color: white;
			color: black;
			padding:16px;
			position:relative;
			z-index:9999;
		}

	}

	// ------------------------------------------------------------------------ //
	// Table Elements
	// ------------------------------------------------------------------------ //

	@if $normalise-table-elements {

		table {
			border-collapse: collapse;
			border-spacing: 0;
		}

	}

	// ------------------------------------------------------------------------ //
	// Form Elements
	// ------------------------------------------------------------------------ //

	@if $normalise-form-elements {

		button,	input, optgroup,	select,	textarea {
			font-family: inherit;
			font-size: 100%;
			line-height: 1.15;
			margin: 0;
		}

		button,	input {
			overflow: visible;
		}

		button,	select {
			text-transform: none;
		}

		button,
		[type="button"],
		[type="reset"],
		[type="submit"] {
			-webkit-appearance: button;
			background:none;
			border:none;
			padding:0;
			margin:0;
			cursor:pointer;
			&:focus { outline:0; }
		}

		button::-moz-focus-inner,
		[type="button"]::-moz-focus-inner,
		[type="reset"]::-moz-focus-inner,
		[type="submit"]::-moz-focus-inner {
			border-style: none;
			padding: 0;
		}

		button:-moz-focusring,
		[type="button"]:-moz-focusring,
		[type="reset"]:-moz-focusring,
		[type="submit"]:-moz-focusring {
			outline: 1px dotted ButtonText;
		}

		fieldset { padding:0;	}

		legend {
			box-sizing: border-box;
			color: inherit;
			display: table;
			max-width: 100%;
			padding: 0;
			white-space: normal;
		}

		progress {
			vertical-align: baseline;
		}

		[type="checkbox"],
		[type="radio"] {
			box-sizing: border-box;
			padding: 0;
		}

		[type="number"]::-webkit-inner-spin-button,
		[type="number"]::-webkit-outer-spin-button {
			height: auto;
		}

		[type="search"] {
			-webkit-appearance: textfield;
			outline-offset: -2px;
		}

		[type="search"]::-webkit-search-decoration {
			-webkit-appearance: none;
		}
		::-webkit-file-upload-button {
			-webkit-appearance: button;
			font: inherit;
		}

		[disabled] {
			cursor: default;
		}

		@if mixin-exists(input-fields) {
			@include input-fields() {
				border-radius:0;
				border:0;
				text-rendering: optimizeLegibility;
				-webkit-appearance:none;
				-webkit-border-radius:0;
				-webkit-font-smoothing: antialiased;

				&:focus { outline:0; }

				@if mixin-exists(placeholder) {
					@include placeholder();
				}
			}
		}

	}

	// ------------------------------------------------------------------------ //
	// Embedded Elements
	// ------------------------------------------------------------------------ //

	@if $normalise-embedded-elements {

		img {
			border-style: none;
		}

	}

	// ------------------------------------------------------------------------ //
	// Text Elements
	// ------------------------------------------------------------------------ //

	@if $normalise-text-elements {

		em {
			font-style:italic;
		}

		a {
		  background-color:transparent;
			text-decoration:none;
			color:$text;
		}

		abbr[title] {
		  border-bottom: none;
		  text-decoration: underline;
		  text-decoration: underline dotted;
		}

	  b, strong {
		  font-weight: bold;
		}

		code,	kbd, samp {
		  font-family: monospace, monospace;
		  font-size: 1em;
		}

		small {
		  font-size: 80%;
		}

		sub, sup {
		  font-size: 75%;
		  line-height: 0;
		  position: relative;
		  vertical-align: baseline;
		}

		sub {
		  bottom: -0.25em;
		}

		sup {
		  top: -0.5em;
		}

		textarea {
			resize:none;
			overflow: auto;
		}

	}

}
