/* Style the form */
/* Hide all steps by default: */
.tab {
    display: none;
    text-align: center;
    width: 100%;

    &:first-of-type p:first-of-type {
        margin-bottom: 24px;
    }

    a {
        color: $green;
        cursor: pointer;
    }

    &:first-of-type {
        .button{
            margin: 0 auto;
        }
        input-fields {
            width: 80%;
            margin: 0 auto;
            div {
                position: relative;
                margin-left: 5%;
                margin-right: 5%;
                @media(min-width: 841px) {
                    flex-basis: 20%;
                    &:nth-child(1) {
                        margin-left: 27%;
                        margin-right: 1%;
                        max-width: 240px;
                        min-width: 240px;
                    }

                    &:nth-child(2) {
                        margin-right: 27%;
                        margin-left: 1%;
                        max-width: 240px;
                    }

                    &:nth-child(3) {
                        margin-left: 10%;
                        margin-right: 1%;
                    }

                    &:nth-child(4) {
                        margin: 0 1%;
                    }

                    &:nth-child(5) {
                        margin-right: 10%;
                        margin-left: 1%;
                    }
                }
                @media(min-width: 841px) and (max-width: 898px){
                    &:nth-child(1) {
                        margin-left: 20%;
                    }

                    &:nth-child(2) {
                        margin-right: 18%;
                    }
                }
                @media(min-width: 898px) and (max-width: 1064px){
                    &:nth-child(1) {
                        margin-left: 23%;
                    }

                    &:nth-child(2) {
                        margin-right: 19%;
                    }
                }
                @media(min-width: 1064px) and (max-width: 1218px){
                    &:nth-child(1) {
                        margin-left: 24%;
                    }

                    &:nth-child(2) {
                        margin-right: 24%;
                    }
                }
                @media(min-width: 1218px) and (max-width: 1315px){
                    &:nth-child(1) {
                        margin-left: 26%;
                    }

                    &:nth-child(2) {
                        margin-right: 26%;
                    }
                }

                input {
                    position: relative;

                    &.reg-input {
                        padding-left: 40px;
                        position: relative;
                    }

                    &:not(.reg-input) {
                        background-color: $blue1;
                        color: white;

                        &::placeholder {
                            color: white;
                        }
                    }
                }

                span.gb {
                    display: block;
                    position: absolute;
                    width: 25px;
                    height: 60px;
                    left: 0;
                    top: 0;
                    background: url("/assets/images/gb-plate.png") no-repeat center center $blue1;
                    background-size: 20px;
                    z-index: 9;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
            }
        }
    }

    &:nth-of-type(2) {
        div:not(.checkboxes) {
            display: flex;
            flex-direction: row;
            width: 75%;
            margin-left: 25%;
            text-align: left;
            padding: 8px 16px;

            &:nth-of-type(odd) {
                background-color: white;
            }

            label {
                width: 30%;
                font-weight: 700;
                line-height: 40px;
            }

            input {
                border: none;
                color: $blue1;
                background: transparent;
            }

            &:last-of-type {
                margin-bottom: 32px;
            }

            &.car-logo {
                position: absolute;
                left: 0;
                top: 30%;
                width: 25%;
                margin-left: 0;
                text-align: center;
                background-color: transparent;
                display: flex;
                flex-direction: column;

                img {
                    height: auto;
                    width: 128px;
                    margin: 0 auto 24px;
                }

                p {
                    width: 100%;
                }
            }
            @media(max-width: 841px) {
                position: relative;
                width: 100%;
                margin: 0;
                flex-direction: column;

                label {
                    width: 100%;
                }

                input {
                    white-space: pre-wrap;
                }

                &.car-logo {
                    position: relative;
                    width: 100%;
                    top: unset;
                }
            }
        }

        div.checkboxes {
            margin-top: 32px;

            .inputs {
                background-color: transparent;
            }

            label {
                width: 100%;
            }
        }
    }
}
