////////////////////////////////////////////////////////////////////////////////
// Forms
////////////////////////////////////////////////////////////////////////////////
// =============================================================================
// INPUTS
// =============================================================================

input[type="text"],
input[type="number"],
input[type="email"],
input[type="url"] {
    border: 1px solid $grey2;
    border-radius: 5px;
    line-height: 40px;
    width: 100%;
}

application-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px 0;

    input-fields {
        position: relative;
        text-align: center;
        display: block;
    }

    // Form notices - inspired by Bootstrap .help-block - larger font size however
    .form-notice {
        max-width: 100%;
        flex-basis: 100%;
        margin-left: 1%;
        margin-right: 1%;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 3px;

        p {
            font-size: 21px;
            line-height: 1;
        }
    }

    // Multi-submit button form container styles
    .submits {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 32px;
        width: 100%;
        max-width: 100vw;

        > p {
            margin: $gap $gap2 0;
            font-size: 21px;
            line-height: 1;
            padding: 0.5714em 0;
            font-weight: 700;
        }
        input,
        button {
            margin-top: $gap;
        }
        // DEPRECATED - 2023-07-07
        // .applyByPhone{
        //     display: flex;
        //     flex-direction: column;
        //     position: relative;

        //     label {
        //         margin-top: 12px;
        //         font-size: 12px;
        //         list-style: none;
        //         line-height: 1.4;
        //     }
        //     select {
        //         margin-top: 12px;
        //         border: 1px solid $blue1;
        //         background: transparent;
        //         color: $green;
        //         padding: 2px 4px;
        //         border-radius: 4px;
        //         line-height: 24px;
        //     }
        // }

        @include break($medium, '>') {
            width: 70%;
            flex-direction: row;
            // align-items: flex-start;
            justify-content: center;

            > input,
            > button
            // DEPRECATED - 2023-07-07
            // .applyByPhone
            {
                width: 100%;
                max-width: 48%;
            }
        }
    }
}

@keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

// Submit button spam prevention
input[type="submit"],
button[type="submit"] {
    &:disabled {
        background-color: darken($color: $primary, $amount: 10%);
    }

    &.submitter {
        &:before {
            position: absolute;
            left: 50%;
            margin-left: -0.5em;
            content: '';
            height: 1em;
            width: 1em;
            border: 4px solid rgba($color: $white, $alpha: 0.75);
            border-top-color: $primary;
            border-radius: 50%;
            animation: loading 1s linear infinite;
        }
        span {
            visibility: hidden;
        }
    }
}

input-fields {
    // General styles
    .inputs {
        a {
            color: $green;
        }
    }

    // Input containers
    & > div,
    .checkboxes {
        margin-bottom: 2%;

        &.inputs {
            width: 100%;
            user-select: none;
            padding: 10px 0;
            font-size: 13px;
            text-align: left;
            margin-bottom: 0;
        }
    }

    // Checkbox list
    .checkboxes {
        font-size: 13px;
        max-width: 100%;

        .inputs {
            max-width: 100%;
            margin: 16px 0 0 1%;
            text-align: left;
        }
    }
}

input.large, select.large {
    max-width: 100%;
    height: 60px;
    border: 1px solid $grey2;
    padding: 5px 15px;
    @include border-radius(5);
    color: $blue1;
    line-height: 60px;
    position: relative;

    &:active,
    &:focus,
    &:hover {
        border-color: $black;
    }

    &::-webkit-input-placeholder {
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        color: $blue1;
        font-family: 'Arial';
    }

    &::-moz-placeholder {
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        color: $blue1;
        font-family: 'Arial';
    }

    &:-ms-input-placeholder {
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        color: $blue1;
        font-family: 'Arial';
    }

    &::-ms-input-placeholder {
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        color: $blue1;
        font-family: 'Arial';
    }

    &:-moz-placeholder {
        text-transform: none;
        font-size: 16px;
        font-weight: 400;
        color: $blue1;
        font-family: 'Arial';
    }

    &.reg-input {
        background: $yellow;
    }

    &[class*="-input"] {
        @extend .mandator;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 35px;
    }
}

[data-browser*='edge'] {
    input.large {
        line-height: 0;
    }
}
// Safari does wierd things with placeholder positioning
html:not([data-browser*='safari']) {
    input.large[class*="-input"] {
        &::-webkit-input-placeholder {
            transform: translateY(-50%);
        }

        &::-moz-placeholder {
            transform: translateY(-50%);
        }

        &:-ms-input-placeholder {
            transform: translateY(-50%);
        }

        &:-moz-placeholder {
            transform: translateY(-50%);
        }
    }
}
@include break($medium, '>') {
    application-form {
        input-fields {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            flex-wrap: wrap;

            > div {
                max-width: 31%;
                margin-left: auto;
                margin-right: auto;
                flex-basis: 33.3%;
                flex-grow: 1;
                flex-shrink: 0;
            }
        }
    }
}
@include break($large, '>') {
    application-form {
        padding: 50px 0;
    }
}


/* Base for label styling */
.checkbox {
    appearance: checkbox;
}

[type="checkbox"]:checked,
.checkbox:checked,
[type="checkbox"]:not(:checked),
.checkbox:not(:checked) {
    position: absolute;
    //left: -9999px;
    opacity: 0;
}

[type="checkbox"]:checked + label,
.checkbox:checked + label,
[type="checkbox"]:not(:checked) + label,
.checkbox:not(:checked) + label {
    position: relative;
    padding-left: 2.1333em;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
}
/* checkbox aspect */
[type="checkbox"]:checked + label:before,
.checkbox:checked + label:before,
[type="checkbox"]:not(:checked) + label:before,
.checkbox:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 1.5em;
    height: 1.5em;
    border: 2px solid #cccccc;
    background: #ffffff;
    border-radius: 5px;
    transform: translateY(-50%);
    //box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
}
/* checked mark aspect */
[type="checkbox"]:checked + label:after,
.checkbox:checked + label:after,
[type="checkbox"]:not(:checked) + label:after,
.checkbox:not(:checked) + label:after {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 0;
    font-size: 1.5em;
    line-height: 1;
    height: 1em;
    width: 1em;
    transform: translateY(-50%);
    color: $blue1;
    transition: opacity 0.2s, transform 0.2s;
    margin: -0.5em 0.1em;
}
/* checked mark aspect changes */
[type="checkbox"]:not(:checked) + label:after,
.checkbox:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0.4);
}

[type="checkbox"]:checked + label:after,
.checkbox:checked + label:after {
    opacity: 1;
    transform: scale(1);
}
/* disabled checkbox */
[type="checkbox"]:disabled:checked + label:before,
.checkbox:disabled:checked + label:before,
[type="checkbox"]:disabled:not(:checked) + label:before,
.checkbox:disabled:not(:checked) + label:before {
    box-shadow: none;
    border-color: #999999;
    background-color: #dddddd;
}

[type="checkbox"]:disabled:checked + label:after,
.checkbox:disabled:checked + label:after {
    color: #999999;
}

[type="checkbox"]:disabled + label,
.checkbox:disabled + label {
    color: #aaaaaa;
}


// Radio buttons
.radios {
    > * {
        margin-top: $gap;
    }
    label {
        position: relative;
        padding-right: $gap2;
        display: block;
        font-weight: 400;
        line-height: 1.5625;
        cursor: pointer;

        & + label {
            margin-left: $gap2;
        }

        &:hover, &:focus, &:active {
            .radiobtn {
                border-color: $tulip-tree;
            }
        }
    }
    input {
        position:absolute;
        opacity: 0;

        &:checked ~ .radiobtn:after {
            display: block;
        }
    }
    .radiobtn {
        position: absolute;
        top: 0;
        right: 0;
        height: 25px;
        width: 25px;
        background-color: $mine-shaft;
        border-radius: 50%;;
        border-width: 1px;
        border-style: solid;
        border-color: $mine-shaft;
        transition: border-color .3s ease-in-out;;

        &:after {
            content: '';
            position: absolute;
            display: none;
            width: 15px;
            height: 15px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-color: $tulip-tree;;
        }
    }
}



label {
    transition: color 300ms;
}

label:before {
    transition: color 300ms;
}

label:hover {
    color: $blue2;
}

label:hover:before {
    border-color: $blue2;
    background: #ffffff;
}

// NHS badge ...here?
.nhs-discount{
    position: absolute;
    right: 0px;
    top: 200px;
    height: 80px;
    width: 80px;
    @include break($min, '>') {
        right: 0px;
        top: 190px;
        height: 80px;
        width: 80px;
    }
    @include break($small, '>') {
        right: 15px;
        top: 75px;
        height: 100px;
        width: 100px;
    }
    @include break($large, '>') {
        right: 30px;
        top: 30px;
        height: 110px;
        width: 110px;
    }
}



.select-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: fit-content;

    select {
        width: 100%;
        -webkit-appearance: none;
        appearance: none;

        &.large {
            padding-top: 0px;
            padding-right: 25px;
        }

        option {
            vertical-align: middle;
        }
    }

    &::after {
        content: "▼";
        font-size: 2rem;
        top: 50%;
        transform: translateY(-50%);
        right: 10px;
        position: absolute;
        pointer-events: none;
    }
}
